var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a id=\"legal-notice\" class=\"legal-notice\" data-i18n=\"legalnotice\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"hrefLegalNotice") || (depth0 != null ? lookupProperty(depth0,"hrefLegalNotice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hrefLegalNotice","hash":{},"data":data,"loc":{"start":{"line":1,"column":72},"end":{"line":1,"column":91}}}) : helper)))
    + "\" target=\"_blank\" >Legal notice</a> -\n<a class=\"personal-data\" data-i18n=\"personalData\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"hrefPersonalData") || (depth0 != null ? lookupProperty(depth0,"hrefPersonalData") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hrefPersonalData","hash":{},"data":data,"loc":{"start":{"line":2,"column":56},"end":{"line":2,"column":76}}}) : helper)))
    + "\" target=\"_blank\" >Personal data</a> -\n<a class=\"accessibility\" data-i18n=\"accessibility\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"hrefAccessibility") || (depth0 != null ? lookupProperty(depth0,"hrefAccessibility") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hrefAccessibility","hash":{},"data":data,"loc":{"start":{"line":3,"column":57},"end":{"line":3,"column":78}}}) : helper)))
    + "\" target=\"_blank\" >Accessibility</a>";
},"useData":true});