var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 id=\"main-menu-label\" class=\"visually-hidden\" data-i18n=\"menu.mainMenu\"></h2>\n<a id=\"catalog-button\" href=\"#\" role=\"button\" class=\"btn left-bar-btn btn-donnees\" data-href=\"donnees\" data-i18n=\"[title]menu.data.label;[aria-label]menu.data.label\" >\n  <i class=\"fas fa-2x fa-layer-group\"></i>\n</a>\n<a href=\"#\" role=\"button\" class=\"btn left-bar-btn btn-dessin "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasAccessCartodyn") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":61},"end":{"line":5,"column":114}}})) != null ? stack1 : "")
    + "\" data-href=\"dessin\" data-i18n=\"[title]menu.draw.label;[aria-label]menu.draw.label\">\n  <i class=\"fas fa-2x fa-pencil-ruler\"></i>\n</a>\n<a href=\"#\" role=\"button\" class=\"btn left-bar-btn btn-affichage\" data-href=\"affichage\" data-i18n=\"[title]menu.settings.label;[aria-label]menu.settings.label\">\n  <i class=\"fas fa-2x fa-cog\"></i>\n</a>\n<a href=\"#\" role=\"button\" class=\"btn left-bar-btn btn-contact\" data-href=\"contact\" data-i18n=\"[title]menu.contact.label;[aria-label]menu.contact.label\">\n  <i class=\"far fa-2x fa-comment-dots\"></i>\n</a>\n";
},"useData":true});