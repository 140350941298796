const $ = require('jquery');
const ShomView = require('../../core/shom-view');
const Loading = require('../../utils/loading');
const loginTemplate = require('../../../template/user-menu/user-menu.hbs');
const User = require('../../model/user');
const FormValidationData = require('../../data/forms/login-form');

const AnonymousView = ShomView.build({

  initialize(options = {}) {
    this._router = options.router || window.ROUTER;
    this._config = options.config || window.CONFIG;
    this._eventsBus = options.eventsBus || window.EVENTBUS;
  },

  render() {
    this.$el.html(loginTemplate({
      registerUrl: this._config.diffusion.createAccount
    }));

    this.$('form.user-credentials').bootstrapValidator({
      submitButtons: 'button.user-connection',
      fields: FormValidationData()
    }).on('success.form.bv', this.onLoginFormSubmit.bind(this));

    return this;
  },

  showError(i18nKey) {
    const $error = this.$('.connection-error');

    $error.empty();

    $("<div class='help-block'></div>")
      .html($.t(i18nKey))
      .appendTo($error);

    $error.attr('tabindex', 0);
    $error.focus();
  },

  logUser(usr) {
    const $form = this.$('form.user-credentials');
    Loading.start($form);

    const promise = usr.authenticate();
    const router = this._router;

    promise
      .then(() => router.user.loggedIn(usr))
      .fail(errorCode => {
        const errorKey = User.AUTH_ISSUES.BAD_CREDENTIALS === errorCode ? 'user.errors.badcredentials'
          : User.AUTH_ISSUES.NETWORK === errorCode ? 'user.errors.requestfailure' : '';

        this.showError(errorKey);
      })
      .then(loggedInUser => loggedInUser.fetchLayers())
      .always(() => {
        Loading.stop($form);
        this._eventsBus.trigger('catalog:refresh');
      });
  },

  onLoginFormSubmit(event) {
    (event.preventDefault) ? event.preventDefault() : event.returnValue = false;

    const $form = this.$('form.user-credentials');
    Loading.start($form);

    const userLogin = $('input[name=user-login]').val();
    const getUserKeyUrl = `${this._config.accountSynchro.getUserKeyUrl}/${userLogin}`;
    $.get({
      url: getUserKeyUrl,
      async: false
    }).done(userKey => {
      const usr = new User({
        key: userKey,
        username: userLogin,
        password: $('input[name=user-password]').val()
      });
      this.logUser(usr);
    }).fail(error => {
      const errorKey = error.status === 400 ? 'user.errors.accountNotSynchro' : 'user.errors.requestfailure';
      this.showError(errorKey);
    }).always(() => {
      Loading.stop($form);
    });
  }
});

module.exports = AnonymousView;
