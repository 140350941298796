var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"legend-view oceano-legend-text\">\n    <div data-i18n=\"[html]oceanogramme.presentation.content\"></div>\n    <ul>\n        <li><span data-i18n=\"oceanogramme.presentation.legend.standardPoint\"></span>\n          <img class=\"icon-legend-oceano\" data-i18n=\"[alt]oceanogramme.presentation.legend.standardPointLegend\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imgSpotTriangle") || (depth0 != null ? lookupProperty(depth0,"imgSpotTriangle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imgSpotTriangle","hash":{},"data":data,"loc":{"start":{"line":5,"column":117},"end":{"line":5,"column":136}}}) : helper)))
    + "\"/></li>\n        <li><span data-i18n=\"oceanogramme.presentation.legend.ronimPoint\"></span>\n          <img class=\"icon-legend-oceano\" data-i18n=\"[alt]oceanogramme.presentation.legend.ronimPointLegend\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imgSpotPoint") || (depth0 != null ? lookupProperty(depth0,"imgSpotPoint") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imgSpotPoint","hash":{},"data":data,"loc":{"start":{"line":7,"column":114},"end":{"line":7,"column":130}}}) : helper)))
    + "\"/></li>\n        <li><span data-i18n=\"oceanogramme.presentation.legend.anyPoint\"></span></li>\n    </ul>\n    <div data-i18n=\"oceanogramme.presentation.legend.hoverInfo\"></div>\n</div>\n";
},"useData":true});