var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <iframe class=\"iframe-gfi\" id=\"iframe-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":12,"column":54},"end":{"line":12,"column":64}}}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":12,"column":73},"end":{"line":12,"column":82}}}) : helper)))
    + "\" seamless frameborder=\"0\"></iframe>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel panel-default\">\n    <div class=\"panel-heading gfi-modal-title\">\n        <h4 class=\"panel-title\">\n            <a class=\"gfi-accordion-toggle"
    + alias4(((helper = (helper = lookupProperty(helpers,"toogleCollapse") || (depth0 != null ? lookupProperty(depth0,"toogleCollapse") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toogleCollapse","hash":{},"data":data,"loc":{"start":{"line":4,"column":42},"end":{"line":4,"column":60}}}) : helper)))
    + "\" data-toggle=\"collapse\" data-parent=\"#accordion\"\n               href=\"#collapse"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":40}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":42},"end":{"line":5,"column":51}}}) : helper)))
    + "</a>\n        </h4>\n    </div>\n    <div id=\"collapse"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":8,"column":21},"end":{"line":8,"column":31}}}) : helper)))
    + "\" class=\"panel-collapse collapse"
    + alias4(((helper = (helper = lookupProperty(helpers,"collapse") || (depth0 != null ? lookupProperty(depth0,"collapse") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"collapse","hash":{},"data":data,"loc":{"start":{"line":8,"column":63},"end":{"line":8,"column":75}}}) : helper)))
    + "\">\n        <div class=\"panel-body\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"htmlTSContent") || (depth0 != null ? lookupProperty(depth0,"htmlTSContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"htmlTSContent","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":31}}}) : helper))) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRadarHF") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":13,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});