var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"customTitle") : depth0), depth0))
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"layerLocalTitle") || (depth0 != null ? lookupProperty(depth0,"layerLocalTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"layerLocalTitle","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":37}}}) : helper)))
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"7":function(container,depth0,helpers,partials,data) {
    return "catalog.button.maxextent.tooltipDisabled";
},"9":function(container,depth0,helpers,partials,data) {
    return "catalog.button.maxextent.tooltip";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a class=\"disp-layer-tools-download-button\" target=\"_blank\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"layer") : depth0)) != null ? lookupProperty(stack1,"downloadUrl") : stack1), depth0))
    + "\"\r\n           data-i18n=\"[title]catalog.button.download.tooltip;[aria-label]catalog.button.download.tooltip\">\r\n            <i class=\"fas fa-download "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isDownloadEnabled") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":38},"end":{"line":30,"column":86}}})) != null ? stack1 : "")
    + " tab-material-icons\"></i>\r\n        </a>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"14":function(container,depth0,helpers,partials,data) {
    return "            <a href=\"#\" role=\"button\" class=\"more-info-button\" data-i18n=\"[title]catalog.button.info.tooltip;[aria-label]catalog.button.info.tooltip\"><i class=\"fas fa-angle-double-down\"></i></a>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"displayed-layer-item\">\r\n\r\n    <div class=\"remove-button  disp-layer-presentation-buttons\" role=\"presentation\" >\r\n        <a href=\"#\" role=\"button\" data-i18n=\"[title]catalog.button.addremove.tooltip;[aria-label]catalog.button.addremove.tooltip\">\r\n            <i class=\"material-icons tab-material-icons\">clear</i>\r\n        </a>\r\n    </div>\r\n    <div class=\"layer-item-first-line\">\r\n        <span class=\"displayed-layer-title-container\" title=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"layerTitle") || (depth0 != null ? lookupProperty(depth0,"layerTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"layerTitle","hash":{},"data":data,"loc":{"start":{"line":9,"column":61},"end":{"line":9,"column":75}}}) : helper)))
    + "\" tabindex=\"0\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"customTitle") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":14,"column":19}}})) != null ? stack1 : "")
    + "        </span>\r\n    </div>\r\n\r\n    <div class=\"layer-item-second-line\">\r\n        <span class=\"disp-layer-presentation-buttons\" role=\"presentation\">\r\n            <span class=\"visibility-root tab-material-icons\"></span>\r\n            <a class=\"zoom-to-max-extend"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMaxExtentDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":40},"end":{"line":21,"column":83}}})) != null ? stack1 : "")
    + "\" role=\"button\" href=\"#\"\r\n               data-i18n=\"[title]"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMaxExtentDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":22,"column":33},"end":{"line":22,"column":147}}})) != null ? stack1 : "")
    + ";[aria-label]"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMaxExtentDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":22,"column":160},"end":{"line":22,"column":274}}})) != null ? stack1 : "")
    + "\">\r\n                <i class=\"fas fa-expand\"></i>\r\n            </a>\r\n        </span>\r\n        <div class=\"opacity-slider-root\" data-i18n=\"[title]catalog.slider.opacity.tooltip\"></div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"layer") : depth0)) != null ? lookupProperty(stack1,"downloadUrl") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMoreInfo") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":35,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n    <div class=\"more-info-container\"></div>\r\n\r\n</div>\r\n";
},"useData":true});