var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n      <a id=\"skip-links-menu\" role=\"button\" href=\"#\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"labelMenu") || (depth0 != null ? lookupProperty(depth0,"labelMenu") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"labelMenu","hash":{},"data":data,"loc":{"start":{"line":11,"column":53},"end":{"line":11,"column":66}}}) : helper)))
    + "</a>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav role=\"navigation\">\n  <ul id=\"skip-links-list\">\n    <li>\n      <a id=\"skip-links-content\" role=\"button\" href=\"#\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"labelContent") || (depth0 != null ? lookupProperty(depth0,"labelContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"labelContent","hash":{},"data":data,"loc":{"start":{"line":4,"column":56},"end":{"line":4,"column":72}}}) : helper)))
    + "</a>\n    </li>\n    <li>\n      <a id=\"skip-links-header\" role=\"button\" href=\"#\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"labelHeader") || (depth0 != null ? lookupProperty(depth0,"labelHeader") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"labelHeader","hash":{},"data":data,"loc":{"start":{"line":7,"column":55},"end":{"line":7,"column":70}}}) : helper)))
    + "</a>\n    </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"labelMenu") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "    <li>\n      <a id=\"skip-links-footer\" role=\"button\" href=\"#\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"labelFooter") || (depth0 != null ? lookupProperty(depth0,"labelFooter") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"labelFooter","hash":{},"data":data,"loc":{"start":{"line":15,"column":55},"end":{"line":15,"column":70}}}) : helper)))
    + "</a>\n    </li>\n  </ul>\n</nav>";
},"useData":true});