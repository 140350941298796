var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img class=\"img-responsive\" alt=\"layer legend\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"legendUrl") || (depth0 != null ? lookupProperty(depth0,"legendUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"legendUrl","hash":{},"data":data,"loc":{"start":{"line":11,"column":64},"end":{"line":11,"column":79}}}) : helper)))
    + "\" />\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"legendUrl") || (depth0 != null ? lookupProperty(depth0,"legendUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"legendUrl","hash":{},"data":data,"loc":{"start":{"line":13,"column":21},"end":{"line":13,"column":36}}}) : helper)))
    + "\" target=\"_blank\" data-i18n=\"legend.layer.legendaccess\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"legendLabel") || (depth0 != null ? lookupProperty(depth0,"legendLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"legendLabel","hash":{},"data":data,"loc":{"start":{"line":13,"column":92},"end":{"line":13,"column":109}}}) : helper)))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"main-legend-panel-top\">\n    <a class=\"main-legend-panel-top-close-button\" href=\"#\" role=\"button\" data-i18n=\"[title]menu.button.close\">\n      <i class=\"fas fa-times\"></i>\n    </a>\n</div>\n<div class=\"main-legend-panel-content\">\n    <div class=\"main-legend-panel-title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":41},"end":{"line":7,"column":52}}}) : helper)))
    + "</div>\n    <div class=\"main-legend-panel-list\">\n        <div class=\"main-legend-panel-list-item\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"legendIsImage") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});