const $ = require('jquery');
const _ = require('underscore');
const ShomView = require('../core/shom-view');
const GlobalDataStore = require('../core/global-data-store');

module.exports = ShomView.build({
  tagName: 'a',

  className: 'show-hide-button materials-icons',

  attributes: {
    role: 'button',
    href: '#'
  },

  events: {
    click: '_onClick'
  },

  initialize(options = {}) {
    this.attributes.title = $.i18n.t('catalog.button.visibility.tooltip');
    this.attributes['aria-abel'] = $.i18n.t('catalog.button.visibility.tooltip');
    this._collection = options.collection;
    this._gfiModeManager = options.poiModeManager || window.POI_MODE_MANAGER;
    this.listenTo(this.model, 'change:visibility', this.render.bind(this));
  },

  render() {
    if (this.model.get('visibility') === true) {
      this.$el.addClass('layer-visible');
      this.$el.html('<i class="far fa-eye"></i>');
    } else {
      this.$el.removeClass('layer-visible');
      this.$el.html('<i class="far fa-eye-slash"></i>');
    }
    return this;
  },

  _onClick() {
    const displayedLayers = GlobalDataStore.getDisplayedLayers();
    const visibility = !this.model.get('visibility');
    this.model.set('visibility', visibility);
    if (this._collection) {
      _.each(this._collection, model => {
        model.set('visibility', visibility);
      });
    }

    const forecastChildren = this.model.get('forecastChildren');
    if (forecastChildren) {
      forecastChildren.forEach(child => child.set('visibility', visibility));
    }
    this._gfiModeManager.isLayerRequestable(displayedLayers);
  }

});
