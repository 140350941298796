var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <tr>\n        <th colspan=\"2\">\n          <label class=\"info-label nodata\">\n            <span data-i18n=\"ddm.textual.data.noData\"></span>\n          </label>\n        </th>\n      </tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <tr>\n        <th>\n          <label class=\"info-label\">\n            <span data-i18n=\"ddm.textual.data.tmcLabel\"></span>\n          </label>\n        </th>\n        <th>\n          <label class=\"info-label\">\n            <span data-i18n=\"ddm.textual.data.heightLabel\"></span>\n          </label>\n        </th>\n      </tr>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <tr>\n        <td><span>"
    + alias1(__default(require("../../../js/hbs-helpers/datetimeformat.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"time") : depth0),(depths[1] != null ? lookupProperty(depths[1],"observationDateFormat") : depths[1]),{"name":"datetimeformat","hash":{},"data":data,"loc":{"start":{"line":43,"column":18},"end":{"line":43,"column":71}}}))
    + "</span></td>\n        <td><span>"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</span></td>\n      </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-striped table-observation\">\n  <tbody>\n    <tr>\n      <th colspan=\"2\">\n        <label class=\"info-label\">\n           <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tidegaugeName") : depth0), depth0))
    + " - "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"idSensor") : depth0), depth0))
    + "</span>\n        </label>\n      </th>\n    </tr>\n    <tr>\n      <th colspan=\"2\">\n        <label class=\"info-label\">\n          <span data-i18n=\"ddm.textual.data.sourceLabel\"></span>\n          <span data-i18n=\"ddm.labels.source"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"tidegaugeObservations") : depth0)) != null ? lookupProperty(stack1,"source") : stack1), depth0))
    + "\"></span>\n        </label>\n      </th>\n    </tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"tidegaugeObservations") : depth0)) != null ? lookupProperty(stack1,"nodata") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"tidegaugeObservations") : depth0)) != null ? lookupProperty(stack1,"observations") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":40,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"tidegaugeObservations") : depth0)) != null ? lookupProperty(stack1,"observations") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":4},"end":{"line":46,"column":13}}})) != null ? stack1 : "")
    + "  </tbody>\n</table>";
},"useData":true,"useDepths":true});