const $ = require('jquery');
const template = require('../../template/skip-links.hbs');
const ShomView = require('../core/shom-view');

module.exports = ShomView.build({
  _currentPage: null,
  _isMenuDisplayed: null,

  _links: {
    default: {
      content: {
        label: 'skiplinks.mainContent',
        id: '#map-container'
      },
      header: {
        label: 'skiplinks.header',
        id: '#top-ribbon-shom-logo'
      },
      menu: {
        label: 'skiplinks.menu',
        id: '#catalog-button'
      },
      footer: {
        label: 'skiplinks.footer',
        id: '#legal-notice'
      }
    },
    ddm_detail: {
      content: {
        label: 'skiplinks.mainContent',
        id: '#chart-button-nearest'
      },
      header: {
        label: 'skiplinks.header',
        id: '#top-ribbon-shom-logo'
      },
      footer: {
        label: 'skiplinks.footer',
        id: '#legal-notice'
      }
    },
    ddm_download: {
      content: {
        label: 'skiplinks.mainContent',
        id: '#ddm-dl-doilink'
      },
      header: {
        label: 'skiplinks.header',
        id: '#top-ribbon-shom-logo'
      },
      footer: {
        label: 'skiplinks.footer',
        id: '#legal-notice'
      }
    },
    oceano: {
      content: {
        label: 'skiplinks.mainContent',
        id: '#oceanogramme-refresh-button'
      },
      header: {
        label: 'skiplinks.header',
        id: '#top-ribbon-shom-logo'
      },
      footer: {
        label: 'skiplinks.footer',
        id: '#legal-notice'
      }
    }
  },

  events: {
    'click #skip-links-header': function () { this._focusOnElement('header'); },
    'click #skip-links-menu': function () { this._focusOnElement('menu'); },
    'click #skip-links-content': function () { this._focusOnElement('content'); },
    'click #skip-links-footer': function () { this._focusOnElement('footer'); }
  },

  initialize(options = {}) {
    this._currentPage = options.currentPage || 'default';
    this._isMenuDisplayed = options.isMenuDisplayed;
  },

  render() {
    this.$el.html(template({
      labelContent: $.i18n.t(this._links[this._currentPage].content.label),
      labelHeader: $.i18n.t(this._links[this._currentPage].header.label),
      labelMenu: this._isMenuDisplayed ? $.i18n.t(this._links[this._currentPage].menu.label) : null,
      labelFooter: $.i18n.t(this._links[this._currentPage].footer.label),
    }));
    return this;
  },

  _focusOnElement(element) {
    $(this._links[this._currentPage][element].id).focus();
  }
});
