var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel-heading mainpanel-heading\">\n  <h4 class=\"panel-title\">\n    <a role=\"listitem\" class=\"accordion-toggle collapsed link-collapse-category\" data-toggle=\"collapse\" data-parent=\"#categories-accordion\" href=\"#collapse-category-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"category") : depth0), depth0))
    + "\">\n        "
    + alias2(((helper = (helper = lookupProperty(helpers,"categoryTranslate") || (depth0 != null ? lookupProperty(depth0,"categoryTranslate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"categoryTranslate","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":29}}}) : helper)))
    + "\n    </a>\n  </h4>\n</div>\n<div id=\"collapse-category-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"category") : depth0), depth0))
    + "\" class=\"panel-collapse collapse\">\n  <div role=\"list\" class=\"panel-body category-layers-root\">\n\n  </div>\n</div>\n";
},"useData":true});