var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button id=\"close-account\" type=\"button\" class=\"close close-top-menu\" data-i18n=\"[aria-label]menu.button.close\">×</button>\n<div class=\"panel-group\" id=\"accordion\">\n  <div class=\"panel panel-default\">\n\n    <div class=\"panel-collapse\">\n      <div class=\"panel-body\">\n        <form class=\"user-form user-credentials\">\n          <div class=\"form-group\">\n            <label for=\"user-login\" class=\"visually-hidden\" data-i18n=\"user.login\"></label>\n            <input id=\"user-login\" class=\"user-login\" type=\"text\" name=\"user-login\" data-i18n=\"[placeholder]user.login\" required/>\n          </div>\n\n          <div class=\"form-group\">\n            <label for=\"user-password\" class=\"visually-hidden\" data-i18n=\"user.password\"></label>\n            <input id=\"user-password\" class=\"user-password\" type=\"password\" name=\"user-password\" data-i18n=\"[placeholder]user.password\" required/>\n          </div>\n\n          <div class=\"connection-error has-error\">\n          </div>\n\n          <div class=\"forgot-pass-actions user-menu-action-buttons\">\n              <button class=\"user-connection btn btn-primary btn-submit\" type=\"submit\" data-i18n=\"user.connect\"></button>\n              <a href=\"#\" role=\"button\" id=\"forgot-password-link\" data-i18n=\"user.forgotPass.question\"></a>\n              <a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"registerUrl") || (depth0 != null ? lookupProperty(depth0,"registerUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"registerUrl","hash":{},"data":data,"loc":{"start":{"line":24,"column":23},"end":{"line":24,"column":40}}}) : helper)))
    + "\" target='blank' data-i18n=\"account.register\"></a>\n          </div>\n\n        </form>\n        <div class=\"user-synchro-container\">\n          <h1 class=\"user-synchro-title\" data-i18n=\"account.synchro.title\"></h1>\n          <p data-i18n=\"account.synchro.description\"></p>\n          <button class=\"user-synchro-btn btn btn-primary\" type=\"button\" data-i18n=\"account.synchro.button\"></button>\n          <p data-i18n=\"account.synchro.precision\"></p>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});