import VectorSource from 'ol/source/Vector';
import Graticule from 'ol/layer/Graticule';
import Stroke from 'ol/style/Stroke';
import TileWMSSource from 'ol/source/TileWMS';
import { Vector as VectorLayer } from 'ol/layer';
import TileLayer from 'ol/layer/Tile';
import { METERS_PER_UNIT } from 'ol/proj/Units';
import * as olControl from 'ol/control.js';
import { defaults as interactionDefaults } from 'ol/interaction.js';
import { unByKey } from 'ol/Observable.js';
import * as olExtent from 'ol/extent';
import { Icon } from 'ol/style';
import { default as ShomCarto } from '@shom2/carto';
import Dialog from 'bootstrap-dialog';
import {
  convertCoordinateDecimalToFormattedDMS,
  DEG_MIN_DIR_FORMAT,
  PROJECTION_WGS84_DMS
} from '@shom2/carto/utils/coordinate';
import {
  LAYERTYPE_FORECAST,
  SC_DRAWING_MODULE,
  SC_MEASUREMENT_MODULE,
  SC_MEASUREMENT_MODULE_ACTIVE
} from '../utils/constants';

import NavigationHistory from '../mixins/openlayers/navigation-history';

import NginxWebPageBrowser from '../utils/nginx-web-page-browser';
import {
  drawingDefaultStyle,
  measurementFormatFunction,
  measurementTemplate, measurementToolTemplate,
  svgDrawingCircle,
  svgDrawingLine,
  svgDrawingPolygon, toolTemplateInfos
} from '../utils/shom-carto-templates-overrides';
import AtlasHelper from '../utils/atlas/atlas-utils';

const Backbone = require('backbone');
const _ = require('underscore');
const $ = require('jquery');
const measureCtrl = require('../utils/gis/measure-control.js');
const WmcContextHelper = require('../utils/gis/context');
const CoordsHelper = require('../utils/gis/coordinates-helper.js');
const OceanoLayerUtils = require('../utils/oceano/oceano-layer.js');
const ToastrUtil = require('../utils/toastr.js');
const Loading = require('../utils/loading');
const GlobalDataStore = require('../core/global-data-store');

'use strict';

const lonFormatter = function (lon) {
  let formattedLon = Math.abs(Math.round(lon * 1000) / 1000);
  formattedLon += (lon < 0) ? 'W' : ((lon > 0) ? 'E' : '');
  return formattedLon;
};

const latFormatter = function (lat) {
  let formattedLat = Math.abs(Math.round(lat * 1000) / 1000);
  formattedLat += (lat < 0) ? 'S' : ((lat > 0) ? 'N' : '');
  return `\n\n${formattedLat}`;
};

// Overriding format function to increase precision to 4
PROJECTION_WGS84_DMS.coordinatesFormat.decimalToFormat = function (coordinate, coordinatePart, precision = 4) {
  return convertCoordinateDecimalToFormattedDMS(coordinate, coordinatePart, DEG_MIN_DIR_FORMAT, precision);
};

module.exports = Backbone.View.extend({
  className: 'gis-view',

  controls: olControl
    .defaults({
      rotate: false,
      zoom: false
    })
    .extend([
      new olControl.ScaleLine({
        className: 'ol-scale-line',
        target: document.getElementById('scale-line')
      })
    ]),

  initialize(options = {}) {
    this._options = options;
    this._config = options.config || window.CONFIG;
    this._router = options.router || window.ROUTER;
    this._layers = options.layers || window.LAYERS;
    this._user = this._router.user;
    this.eventsBus = options.eventsBus;
    this.defaultCursor = options.defaultCursor || 'auto';
    this._drawingLayerTitle = options.displayLayerTitle || 'Drawing layer';
    this._wmcHelper = options.wmcHelper || new WmcContextHelper({ user: this._user });
    this._gfiModeManager = options.gfiModeManager || window.POI_MODE_MANAGER;
    this.currentlyDrawing = false;
    this._nbLayerLoading = 0;

    this.listenTo(this.collection, 'change:includedInMap', _.bind(this._addRemoveLayer, this));
    this.collection.comparator = _.bind(this._collectionComparator, this);

    const olInteractions = interactionDefaults({
      altShiftDragRotate: false,
      pinchRotate: false
    });

    // Création de la couche pour le dessin
    this.drawingLayer = new VectorLayer({
      source: new VectorSource({
        wrapX: true
      })
    });

    // Récupération de la couche de la minimap
    let minimapOlLayer;
    const minimapLayer = this._layers.findWhere({
      identifier: this._config.overViewMap.layer
    });
    if (minimapLayer) {
      minimapOlLayer = _.clone(minimapLayer.get('olLayer'));
    }

    // Instanciation du module carto
    this.carto = new ShomCarto({
      layers: [],
      target: '#map-container',
      projection: this._config.projection,
      center: [this._config.lon, this._config.lat],
      zoom: this._config.zoom,
      minZoom: this._config.minZoom,
      maxZoom: this._config.maxZoom,
      constrainResolution: true,
      controls: this.controls,
      interactions: olInteractions,
      i18n: {
        language: window.portalLang === 'en' ? 'en-US' : 'fr-FR',
        dict: {
          'fr-FR': {
            CONTEXT_MENU_COORD_LABEL: $.i18n.options.resStore.fr.translation.contextMenu.coord,
            CONTEXT_MENU_LEGEND_LABEL: $.i18n.options.resStore.fr.translation.contextMenu.legend,
            DRAWING_EDITFORM_POSITION_TITLE: $.i18n.options.resStore.fr.translation.drawing.editForm.position
          },
          'en-US': {
            CONTEXT_MENU_COORD_LABEL: $.i18n.options.resStore.en.translation.contextMenu.coord,
            CONTEXT_MENU_LEGEND_LABEL: $.i18n.options.resStore.en.translation.contextMenu.legend,
            DRAWING_EDITFORM_POSITION_TITLE: $.i18n.options.resStore.en.translation.drawing.editForm.position
          }
        }
      },
      styleService: {
        style: drawingDefaultStyle
      },
      modules: {
        zoomModule: {
          enable: false
        },
        panModule: {
          enable: false
        },
        minimapModule: {
          enable: false,
          collapsed: false,
          collapsible: true,
          layers: [minimapOlLayer]
        },
        measurementModule: {
          enable: false,
          template: measurementTemplate,
          measurementToolTemplate,
          measurementToolTemplateInfos: toolTemplateInfos,
          formatFunction: measurementFormatFunction,
          availableMeasurementTools: ['distance', 'surface', 'azimuth', 'radius']
        },
        drawingModule: {
          enable: false,
          layer: this.drawingLayer,
          layerZIndex: 1000,
          distanceUnit: 'NAUTIC_MILES',
          modal: {
            draggable: true,
            titleBar: {
              buttons: {
                cross: {
                  enable: false
                },
                minimize: {
                  enable: false
                }
              }
            }
          },
          mainMenu: {
            availableTools: ['point', 'path', 'polygon', 'circle', 'text', 'dragging', 'center'],
            toolTemplateInfos: {
              point: {
                icon: '<i class="fa-solid fa-location-dot"></i>'
              },
              path: {
                icon: svgDrawingLine
              },
              polygon: {
                icon: svgDrawingPolygon
              },
              circle: {
                icon: svgDrawingCircle
              },
              text: {
                icon: '<i class="fa-solid fa-font"></i>'
              }
            },
            editionForm: {
              positionFormOptions: {
                displayVertexLabels: true,
                displayFeatureLabel: true
              }
            }
          }
        },
        contextMenuModule: {
          enable: true,
          items: [
            {
              id: 'coordinates',
              kind: 'SIMPLE',
              label: 'CONTEXT_MENU_COORD_LABEL',
              action: this.onContextMenuDisplayCoordinates.bind(this)
            },
            {
              id: 'legend',
              kind: 'FEATURE',
              label: 'CONTEXT_MENU_LEGEND_LABEL',
              action: this.onContextMenuAddFeatureToLegend.bind(this),
              filter: this.onContextMenuAddFeatureToLegendFilter.bind(this)
            }
          ]
        }
      }
    });

    // Récupération de la liste des icones du référentiel statique
    this._drawingIcons = [];
    this.carto.command('drawing.setIconTree', this._drawingIcons);
    this.fetchDrawingIcons(this._config.cartodyn.icons, this._drawingIcons);

    this._map = window.map = this.carto.map.olMap;
    this._mapView = this._map.getView();
    this._displayOptions = {
      graticule: false,
      overviewMap: false,
      navigation: false,
      measurement: false,
      legend: true
    };
    this.coordSyst = null;
    this.currentCoord = null;
    this.currentPixel = null;
    this._inches_per_meter = 39.3701;
    this._dpi = 72;

    this.createGraticule();
    this.createNavigationHistory();
    this.addMouseControl();
    this.addMapClickControl();
    this.addScaleControl();
    this.loadInitialState();
    this._map.on('singleclick', _.bind(function (evt) {
      const lonlat = CoordsHelper.convertMercatToLonLat(evt.coordinate);
      this.trigger('click', lonlat);
    }, this));

    const target = this._map.getTarget();
    this._$mapTarget = (typeof target === 'string') ? $(`#${target}`) : $(target);
    this._map.on('pointermove', _.bind(evt => {
      const hit = this._map.forEachFeatureAtPixel(
        evt.pixel,
        () => true
      );
      if (hit) {
        this._$mapTarget.css('cursor', 'pointer');
      } else {
        this._$mapTarget.css('cursor', this.defaultCursor);
      }
    }, this));

    $(window).on('resize', () => {
      this._map.updateSize();
    });

    this._nbTilesLoading = 0;
    this._isLoading = false;
    this._initOceano();

    this._oceanogrammeHoveredFeature = null;
    this._oceanogrammeSpotInteraction = null;
    this._oceanogrammeMapClickEvent = null;
  },

  setCurrentlyDrawing(currentlyDrawing) {
    this.currentlyDrawing = currentlyDrawing;
    this.eventsBus.trigger('drawing-mode:change');
  },

  addLoadingTile() {
    this._nbTilesLoading += 1;
  },

  removeLoadingTile() {
    if (this._nbTilesLoading > 0) {
      this._nbTilesLoading -= 1;
    }
    this.stopLoadingIfNoTile();
  },

  startLoading() {
    if (this._isLoading) {
      return;
    }
    this._isLoading = true;
    this._nbTilesLoading = 0;
    Loading.start($('.map-container'), { overlay: false, isAbsolute: true });
  },

  stopLoadingIfNoTile() {
    if (this._nbTilesLoading === 0) {
      this._isLoading = false;
      Loading.stop($('.map-container'));
    }
  },

  loadLayerSpinner(name) {
    this._nbLayerLoading += 1;
    if (!$('body').hasClass('is-loading')) {
      Loading.start($('body'), { overlay: false, isAbsolute: true });
    }
    this.loadLayer(name, true).get('olLayer').on('change', event => {
      if (event.target.getSource().getFeatures().length > 0) {
        window.GISVIEW._nbLayerLoading -= 1;
      }
      if (window.GISVIEW._nbLayerLoading === 0) {
        Loading.stop($('body'));
      }
    });
  },

  loadInitialState() {
    this.removeAllLayers();
    this.loadLayer(this._config.baseLayer, true);
    this._map.getView().setZoom(this._config.zoom);
    this._map.getView().setCenter([this._config.lon, this._config.lat]);
  },

  loadLayer(identifier, addLayer) {
    const layerToUpdate = this.collection.findWhere({
      identifier
    });
    if (layerToUpdate) {
      layerToUpdate.set('includedInMap', addLayer);
    }
    return layerToUpdate;
  },

  isLayerLoaded(identifier) {
    const layerToCheck = this.collection.findWhere({
      identifier
    });
    if (layerToCheck) {
      return layerToCheck.get('includedInMap');
    }
    return false;
  },

  setCoordSyst(coordSyst) {
    this.coordSyst = coordSyst;
    this.eventsBus.trigger('coordSyst:change', {
      coordSyst: this.coordSyst
    });
  },

  createGraticule() {
    this._graticule = new Graticule({
      // the style to use for the lines, optional.
      strokeStyle: new Stroke({
        color: 'rgba(51, 51, 51, 0.5)',
        width: 1
      }),
      showLabels: true,
      lonLabelFormatter: lonFormatter,
      lonLabelPosition: 0.05,
      latLabelFormatter: latFormatter,
      latLabelPosition: 0.95
    });
  },

  createNavigationHistory() {
    const initState = {
      zoom: this._mapView.getZoom(),
      center: this._mapView.getCenter(),
      rotation: this._mapView.getRotation()
    };
    this._history = new NavigationHistory({ init: initState });

    this._map.on('moveend', _.bind(function () {
      const currentState = {
        zoom: this._mapView.getZoom(),
        center: this._mapView.getCenter(),
        rotation: this._mapView.getRotation()
      };
      this._history.createNewPosition(currentState);
      this.eventsBus.trigger('change:moveInMap', {
        state: currentState
      });
      this.updateVisibility();
    }, this));
  },

  updateVisibility() {
    const ncwmsLayers = _.filter(this.collection.models, groupLayer => (groupLayer.get('layerType') === 'NCWMS' && groupLayer.get('includedInMap')));

    let hasAnyModelTypeChanged = false;
    let hasAnyModelTimeChanged = false;
    const displayedModelsTypeName = [];

    _.each(ncwmsLayers, _.bind(function (ncwmsGroupLayer) {
      const changes = this.setZonesVisibility(ncwmsGroupLayer);
      hasAnyModelTypeChanged = hasAnyModelTypeChanged || changes.changeModelType;
      hasAnyModelTimeChanged = hasAnyModelTimeChanged || changes.changeModelTime;
      displayedModelsTypeName.push(changes.modelTypeName);
    }, this));

    if (hasAnyModelTypeChanged || hasAnyModelTimeChanged) {
      ToastrUtil.clear(true);
    }
    if (hasAnyModelTypeChanged) {
      const strDisplayedModelTypes = displayedModelsTypeName.join(', ');
      const toastrMessage = $.i18n.t('oceano.toaster.changeModelType').replace('__MODELTYPE__', strDisplayedModelTypes);
      ToastrUtil.info(toastrMessage);
    }
    if (hasAnyModelTimeChanged) {
      ToastrUtil.info($.i18n.t('oceano.toaster.changeResolutionTimeline'));
    }
  },

  addScaleControl() {
    const view = this;
    this._mapView.on('change:resolution', () => {
      view.eventsBus.trigger('change:scale');
    });
  },

  addMouseControl() {
    this._map.on('pointermove', evt => {
      const pixel = this._map.getEventPixel(evt.originalEvent);
      const position = this._map.getCoordinateFromPixel(pixel);
      this.eventsBus.trigger('change:coord', {
        coordSyst: this.coordSyst,
        coord: position
      });
      this.currentCoord = position;
      this.currentPixel = pixel;
    });
  },

  getCoordinateFromPixel(pixel) {
    return this._map.getCoordinateFromPixel(pixel);
  },

  addMapClickControl() {
    this._mapClickHandler = this.handleMapClick.bind(this);
    this._mapSingleClickHandler = this.handleMapSingleClick.bind(this);
    this._map.on('click', this._mapClickHandler);
    this._map.on('singleclick', this._mapSingleClickHandler);

    this.carto.command('measurement.onmeasurestart').subscribe(this.addClickHandlingCartoModule.bind(this, SC_MEASUREMENT_MODULE_ACTIVE));
    this.carto.command('measurement.onmeasureend').subscribe(this.removeClickHandlingCartoModule.bind(this, SC_MEASUREMENT_MODULE_ACTIVE));
    this.carto.command('measurement.onenable').subscribe(() => { GlobalDataStore.set(SC_MEASUREMENT_MODULE, true); });
    this.carto.command('measurement.ondisable').subscribe(() => { GlobalDataStore.set(SC_MEASUREMENT_MODULE, false); });
    this.carto.command('drawing.onenable').subscribe(this.addClickHandlingCartoModule.bind(this, SC_DRAWING_MODULE));
    this.carto.command('drawing.ondisable').subscribe(this.removeClickHandlingCartoModule.bind(this, SC_DRAWING_MODULE));
    this.carto.command('drawing.ondroppedcontainerchange').subscribe(state => {
      this.expandDrawingModalHeader(state);
    });
  },

  /**
     * Retire un module carto actif en interaction avec la carte et active ou non en conséquence la gestion du GFI
     */
  removeClickHandlingCartoModule(module) {
    GlobalDataStore.set(module, false);
    if (!GlobalDataStore.get(SC_DRAWING_MODULE) && !GlobalDataStore.get(SC_MEASUREMENT_MODULE_ACTIVE)) {
      this._map.on('click', this._mapClickHandler);
      this._map.on('singleclick', this._mapSingleClickHandler);
      this._gfiModeManager && this._gfiModeManager.startGfiRequestableLayer(true);
    }
  },

  /**
     * Expand the drawing modal header if the menu is opened
     * @param state (open or close)
     */
  expandDrawingModalHeader(state) {
    const $drawingModalHeader = $('.sc-drawing-module-container .sc-header-block');
    if (state === 'open') {
      $drawingModalHeader.addClass('drawing-modal-header-expanded');
    } else {
      $drawingModalHeader.removeClass('drawing-modal-header-expanded');
    }
  },

  /**
     * Ajoute un module carto actif en interaction avec la carte et désactive la gestion du GFI
     */
  addClickHandlingCartoModule(module) {
    GlobalDataStore.set(module, true);
    this._map.un('click', this._mapClickHandler);
    this._map.un('singleclick', this._mapSingleClickHandler);
    this._gfiModeManager && this._gfiModeManager.stop();
  },

  handleMapClick(evt) {
    const map = this._map;
    const view = this;

    if (evt.dragging) {
      return;
    }

    const pixel = map.getEventPixel(evt.originalEvent);
    const position = map.getCoordinateFromPixel(pixel);
    view.eventsBus.trigger('click:position', {
      coordSyst: view.coordSyst,
      lonlat: position
    });
    view.currentCoord = position;
    view.currentPixel = pixel;
  },

  handleMapSingleClick(evt) {
    const lonlat = CoordsHelper.convertMercatToLonLat(evt.coordinate);
    this.trigger('click', lonlat);
  },

  zoomIn() {
    this._mapView.animate({ zoom: this._mapView.getZoom() + 1 });
  },

  zoomOut() {
    this._mapView.animate({ zoom: this._mapView.getZoom() - 1 });
  },

  zoomToExtent(extent) {
    this._map.getView().fit(extent, {
      size: this._map.getSize(),
      animation: 1000
    });
  },

  zoomToLayerMaxExtent(layer) {
    const maxExtent = layer.get('olLayer').get('maxExtent');
    if (maxExtent) {
      this.zoomToExtent(maxExtent);
    }
  },

  zoomToLayerRegionMaxExtent(layer) {
    const layers = layer.get('olLayer').getLayers().getArray();
    if (!layers || !layers.length) {
      return;
    }
    const regionExtent = layers[0].get('regExtent');
    if (regionExtent) {
      this.zoomToExtent(regionExtent);
    }
    // zoom 1 level more to be sure to view data
    this.zoomIn();
  },

  getMapExtent() {
    return this._map.getView().calculateExtent(this._map.getSize());
  },

  getMapProjectionWidth() {
    return olExtent.getWidth(this._map.getView().getProjection().getExtent());
  },

  pan(ratioX, ratioY) {
    const mapSize = this._map.getSize();
    const center = this._mapView.getCenter();
    const pixelCenter = this._map.getPixelFromCoordinate(center);
    const newPixelCenter = [pixelCenter[0] + ratioX * mapSize[0], pixelCenter[1] + ratioY * mapSize[1]];
    const newCenter = this._map.getCoordinateFromPixel(newPixelCenter);
    this._mapView.animate({ center: newCenter });
  },

  _getOceanogrammeChildrenLayersCount() {
    const layersArray = this._map.getLayers().getArray();
    return layersArray.reduce((acc, currLayer) => {
      if (this.isOceanogrammeLayer(currLayer)) {
        acc += 1;
      }
      return acc;
    }, 0);
  },

  _isForecastLayer(layer) {
    return layer && layer.get('layerType') === LAYERTYPE_FORECAST;
  },

  moveLayerTotheTop(layer) {
    const layers = this._map.getLayers();
    const index = layer.get('index');
    const currentLayer = layers.removeAt(index);
    layers.insertAt(layers.getLength(), currentLayer);

    this._updateLayersIndex();
  },

  moveLayerUp(to, layer) {
    const layers = this._map.getLayers();
    const indexCurrLayer = layers.getArray().indexOf(layer.get('olLayer'));
    let diff = to;
    let cptUntilForecastFirstChild = 0;
    let moveForecastChildren = false;

    if (indexCurrLayer < 0) {
      return;
    }

    if (!this._isForecastLayer(layer)) {
      const maxBound = layers.getArray().length;
      for (let i = indexCurrLayer; i < maxBound; i++) {
        const searchedLayer = layers.getArray()[i];
        if (this.isOceanogrammeLayer(searchedLayer)) {
          // if immediate parent is FORECAST child then jump children + FORECAST
          diff = cptUntilForecastFirstChild === 1 ? 1 + this._getOceanogrammeChildrenLayersCount() : 1;
          break;
        } else {
          cptUntilForecastFirstChild += 1;
        }
      }
    } else {
      moveForecastChildren = true;
    }

    const currentLayer = layers.removeAt(indexCurrLayer);
    const newIndexCurrLayer = indexCurrLayer + diff;
    layers.insertAt(newIndexCurrLayer, currentLayer);
    this._updateLayersIndex();

    if (moveForecastChildren) {
      const forecastChildren = layer.get('forecastChildren');
      if (forecastChildren) {
        this._moveForecastChildren(forecastChildren.slice().reverse(), layers, diff);
      }
    }
  },

  moveLayerDown(to, layer) {
    const layers = this._map.getLayers();
    const indexCurrLayer = layers.getArray().indexOf(layer.get('olLayer'));
    let diff = to;
    let cptUntilForecastParent = 0;
    let moveForecastChildren = false;

    if (indexCurrLayer < 1) {
      return;
    }

    if (!this._isForecastLayer(layer)) {
      for (let i = 0; i < indexCurrLayer; i++) {
        const searchedLayer = layers.getArray()[indexCurrLayer - i];
        if (this._isForecastLayer(searchedLayer)) {
          // if immediate parent is FORECAST then jump FORECAST + children
          diff = cptUntilForecastParent === 1 ? -1 - this._getOceanogrammeChildrenLayersCount() : -1;
          break;
        } else {
          cptUntilForecastParent += 1;
        }
      }
    } else {
      moveForecastChildren = true;
    }

    if (moveForecastChildren) {
      const forecastChildren = layer.get('forecastChildren');
      if (forecastChildren) {
        this._moveForecastChildren(forecastChildren, layers, diff);
      }
    }

    const currentLayer = layers.removeAt(indexCurrLayer);
    const newIndexCurrLayer = indexCurrLayer + diff;
    layers.insertAt(newIndexCurrLayer, currentLayer);
    this._updateLayersIndex();
  },

  _moveForecastChildren(forecastChildrenArray, layers, diff) {
    forecastChildrenArray.forEach(child => {
      const indexCurrChildLayer = layers.getArray().indexOf(child.get('olLayer'));
      const currentChildLayer = layers.removeAt(indexCurrChildLayer);
      const newIndexCurrChildLayer = indexCurrChildLayer + diff;
      layers.insertAt(newIndexCurrChildLayer, currentChildLayer);
      this._updateLayersIndex();
    });
  },

  removeAllLayers() {
    const layers = this.collection.where({
      includedInMap: true
    });
    _.map(layers, layer => {
      layer.set('includedInMap', false);
    });
  },

  _addRemoveLayer(model) {
    const included = model.changed.includedInMap;
    if (included) {
      if (model.get('layerType') === 'NCWMS' && !model.get('addToMapFromCtx')) {
        if (AtlasHelper.isAtlasLayer(model)) {
          const utc = +this.getGlobalOceanoCurrentUTC() || 0;
          this._gfiModeManager.getDiffFromNearestPm(model, utc)
            .then(result => {
              model.set('currentCoeff', +result.coeff);
              return +result.diff;
            })
            .then(diffFromNearestSpm => AtlasHelper.computeNewDatetimeFromReference(diffFromNearestSpm, model.get('olLayer').get('dimensions').processedTime))
            .then(datetime => OceanoLayerUtils.setNCWMSParamsInGroupLayer(model, {
              date: datetime.utc().toISOString().split('T')[0],
              time: datetime.utc().toISOString().split('T')[1]
            }))
            .then(() => {
              OceanoLayerUtils.setZonesNcwmsParams(model, { coeff: model.get('currentCoeff') });
              this.setZonesVisibility(model);
              this.resetSelectedModelParameters(model);
              this.setPaletteOnTop(model);
              model.set('addToMapFromCtx', false);
              this.addLayer(model.get('olLayer'));
              if (included === false && model.get('external') === true) {
                this.collection.remove(model);
                model.destroy();
              }
              this._updateLayersIndex();
            });
          return;
        }
        const currentOceanoMoment = this.getGlobalOceanoCurrentMoment().toISOString().split('T');
        OceanoLayerUtils.setNCWMSParamsInGroupLayer(model, {
          date: currentOceanoMoment[0],
          time: currentOceanoMoment[1]
        });
        OceanoLayerUtils.setZonesNcwmsParams(model);
        this.setZonesVisibility(model);
        this.resetSelectedModelParameters(model);
        this.setPaletteOnTop(model);
      }
      model.set('addToMapFromCtx', false);
      this.addLayer(model.get('olLayer'));
    } else {
      if (model.get('layerType') === 'NCWMS') {
        this.resetLayerGroupOptions(model);
      } else if (model.get('contextName') && window.EXTERNALCONTEXTS) {
        const externalContext = window.EXTERNALCONTEXTS.findWhere({
          context: model.get('contextName')
        });
        externalContext.set('includedInMap', false);
      }
      this.removeLayer(model.get('olLayer'));
    }

    if (included === false && model.get('external') === true) {
      this.collection.remove(model);
      model.destroy();
    }
    this._updateLayersIndex();
    $('#map-container').attr('aria-label', $.i18n.t('map.title'));
  },

  removeContextLayer(model) {
    const externalContext = window.EXTERNALCONTEXTS.findWhere({
      context: model.get('context')
    });
    externalContext.set('includedInMap', false);
    _.each(model.layers, layerModel => {
      this.removeLayer(layerModel);
    });
    this.collection = this.collection || window.LAYERS;
    this.collection.remove(model);

    model.destroy();
    this._updateLayersIndex();
  },

  addLayer(layer) {
    this._map.addLayer(layer);
  },

  removeLayer(layer) {
    this._map.removeLayer(layer);
  },

  /**
     * removes any mouse interactions with the map (drag, zoom ...)
     */
  stopMouseInteractions() {
    this._map.getInteractions().getArray().slice();
  },

  /**
     * add default mouse interactions with the map (drag, zoom ...)
     */
  startMouseInteractions() {
    const interactions = interactionDefaults({ altShiftDragRotate: false, pinchRotate: false });
    interactions.forEach(inter => this._map.addInteraction(inter));
  },

  addTempWMSLayer(server, layerName, onGFI) {
    const source = new TileWMSSource({
      url: server,
      crossOrigin: 'anonymous',
      params: {
        LAYERS: layerName,
        TRANSPARENT: true,
        format: 'image/png'
      }
    });
    const layer = new TileLayer({
      title: 'SPM',
      source
    });

    this.addLayer(layer);

    let listeningIdentifier = '';

    if (onGFI) {
      listeningIdentifier = this._map.on('singleclick', evt => {
        const resolution = this._map.getView().getResolution();
        const projection = this._map.getView().getProjection();
        const url = source.getFeatureInfoUrl(evt.coordinate, resolution, projection, {
          INFO_FORMAT: 'application/json'
        });
        if (url) {
          $.get(url).then(onGFI);
        }
      });
    }

    return _.bind(function () {
      if (onGFI) {
        unByKey(listeningIdentifier);
      }
      this.removeLayer(layer);
    }, this);
  },

  _collectionComparator(layer) {
    if (layer.get('index') !== 0 && !layer.get('index')) {
      return -150;
    }
    return -layer.get('index');
  },

  startMeasure(handler) {
    measureCtrl.start(this._map, handler);
  },

  stopMeasure() {
    measureCtrl.stop();
  },

  addGraticule() {
    this._graticule.setMap(this._map);
  },

  deleteGraticule() {
    this._graticule.setMap(null);
  },

  _updateMapView(pos) {
    this._mapView.setCenter(pos.center);
    this._mapView.setZoom(pos.zoom);
    this._mapView.setRotation(pos.rotation);
  },

  _updateMapCenter(center) {
    this._mapView.setCenter(center);
  },

  _updateMapZoom(zoom) {
    this._mapView.setZoom(zoom);
  },

  _updateMapScale(scaleParam) {
    const scale = this.getOriginalScale(scaleParam);
    this._mapView.setResolution(scale);
  },

  previousExtent() {
    this._updateMapView(this._history.getPrevious());
  },

  nextExtent() {
    this._updateMapView(this._history.getNext());
  },

  getScale() {
    return Math.floor(this.resolution() * METERS_PER_UNIT.m * this._inches_per_meter * this._dpi);
  },

  getOriginalScale(scaleParam) {
    const scale = Number(String(scaleParam).replace(/ /g, ''));
    return ((scale / METERS_PER_UNIT.m) / this._inches_per_meter) / this._dpi;
  },

  getZoom() {
    return this._mapView.getZoom();
  },

  getRotation() {
    return this._mapView.getRotation();
  },

  getDisplayOptions() {
    return this._displayOptions;
  },

  _updateLayersIndex() {
    let displayedLayers = this._getDisplayedLayers();
    _.each(displayedLayers, function (displayedLayer) {
      displayedLayer.set(
        'index',
        this._map.getLayers().getArray().indexOf(displayedLayer.get('olLayer')),
        { silent: true }
      );
    }, this);
    this.collection.sort();
    // Reordering displayed layers after updating index to be able to get the right palette to display
    displayedLayers = displayedLayers.sort((a, b) => b.get('index') - a.get('index'));
    const firstOceanoLayerDisplayed = this._getFirstOceanoLayerDisplayed(displayedLayers);
    if (firstOceanoLayerDisplayed) {
      this.setPaletteOnTop(firstOceanoLayerDisplayed);
    }
  },

  _getDisplayedLayers() {
    return this.collection.where({ includedInMap: true });
  },

  _getFirstOceanoLayerDisplayed: displayedLayers => _.find(displayedLayers, displayedLayer => {
    if (displayedLayer.get('layerType') === 'NCWMS') {
      return displayedLayer;
    }
  }),

  resolution() {
    return this._map.getView().getResolution();
  },

  getCurrentViewBounds() {
    return this._mapView.calculateExtent(this._map.getSize());
  },

  getMapSize() {
    return this._map.getSize();
  },

  getMapProjection() {
    return this._map.getView().getProjection();
  },

  getMapCenter() {
    return this._map.getView().getCenter();
  },

  listenToMoveEnd(callback) {
    this._map.on('moveend', callback);
    return () => this._map.un('moveend', callback);
  },

  fetchDrawingIcons(url, iconTree) {
    const iconsNginxPage = new NginxWebPageBrowser(url);
    iconsNginxPage.getLinksFromFolder()
      .done(links => {
        links.length > 0 && links.shift(); // We do not want '..' for root dir

        links.forEach(link => {
          if (link.endsWith('/')) {
            const branch = {
              label: link,
              value: []
            };
            this.fetchDrawingIcons(url + link, branch.value);
            iconTree.push(branch);
          } else {
            iconTree.push({
              label: link,
              value: new Icon({
                src: url + link,
                crossOrigin: 'anonymous',
                scale: this._config.cartodyn.drawingIconScale
              })
            });
          }
        });
      })
      .fail(() => {
        const toastrMessage = $.i18n.t('drawing.edit.point.icon.url-fetch-error').replace('__URL__', url);
        ToastrUtil.error(toastrMessage);
      });
  },

  onContextMenuDisplayCoordinates() {
    Dialog.show({
      type: Dialog.TYPE_INFO,
      title: $.i18n.t('coordinates.modal.title'),
      message: this._router.getStaticViewsRenderer().displayCoordView.$el.html()
    });
  },

  onContextMenuAddFeatureToLegendFilter(params) {
    return (this.isOnDrawingMode() && this.drawingLayer === params.layer);
  },

  onContextMenuAddFeatureToLegend(params) {
    this.addFeatureToLegend(params.feature);
  }
});
