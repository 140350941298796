var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p class=\"text-center\" >\n    <span data-i18n=\"[html]ddm.footer.description\"></span>\n    <span data-i18n=\"[html]ddm.footer.description2\"></span><br/>\n    <span data-i18n=\"[html]ddm.footer.description3\"></span><br/>\n    <span data-i18n=\"[html]ddm.footer.description4\"></span><br/>\n    <span data-i18n=\"[html]ddm.footer.description5\"></span><br/>\n    <span data-i18n=\"[html]ddm.footer.description6\"></span><br/>\n    <span data-i18n=\"[html]ddm.footer.description7\"></span><br/>\n</p>\n<hr>\n<div class=\"footer-partner\">\n    <div class=\"footer-logo-container logo-refmar col-sm-4\">\n        <p class=\"footer-title\" data-i18n=\"ddm.footer.titles.pilotage\"></p>\n        <a href=\"http://www.sgmer.gouv.fr\" target=\"_blank\">\n        <img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"logos_url") : depth0), depth0))
    + "DDM/logo_acc_SGMer.png\" alt=\"Secrétariat général de la mer\">\n        </a>\n        <a href=\"https://www.shom.fr\" target=\"_blank\">\n            <img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"logos_url") : depth0), depth0))
    + "SHOM/SHOM.gif\" alt=\"shom\">\n        </a>\n    </div>\n    <div class=\"footer-logo-container col-sm-4\">\n        <p class=\"footer-title\" data-i18n=\"ddm.footer.titles.financial\"></p>\n        <a href=\"http://www.developpement-durable.gouv.fr\" target=\"_blank\">\n            <img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"logos_url") : depth0), depth0))
    + "DDM/logo_acc_MEDDE.png\" alt=\"developpement durable\">\n        </a>\n        <a href=\"http://www.interieur.gouv.fr\" target=\"_blank\">\n            <img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"logos_url") : depth0), depth0))
    + "DDM/logo_acc_MI.png\" alt=\"ministère de l'interieur\">\n        </a>\n        <a href=\"http://www.defense.gouv.fr/\" target=\"_blank\">\n            <img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"logos_url") : depth0), depth0))
    + "DDM/logo_acc_MINDEF.png\" alt=\"ministère de la defense\">\n        </a>\n    </div>\n    <div class=\"footer-logo-container logo-shom col-sm-4\">\n        <p class=\"footer-title\" data-i18n=\"ddm.footer.titles.partner\"></p>\n        <a href=\"https://refmar.shom.fr\" target=\"_blank\">\n            <img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"logos_url") : depth0), depth0))
    + "DDM/producteur.gif\" data-i18n=\"[alt]ddm.logo.alt\">\n        </a>\n    </div>\n</div>\n";
},"useData":true});