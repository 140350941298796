const $ = require('jquery');
const _ = require('underscore');
const i18n = require('i18next-client/i18next.commonjs.withJQuery');
const ShomView = require('../../core/shom-view');

const template = require('../../../template/oceanogramme/oceanogramme.hbs');
const OceanogrammeService = require('../../service/oceanogramme.js');
const WFSService = require('../../service/wfs.js');
const Loading = require('../../utils/loading');
const urlHelper = require('../../utils/url-helper.js');
const SkipLinksView = require('../skip-links.view');

const defaultDeltaForecast = 0;
const defaultDeltaObservation = -3;
const defaultDuration = 4;

module.exports = ShomView.build({
  events: {
    'click .oceanogramme-back-button': '_onBackClick',
    'click .oceanogramme-refresh-button': '_refresh'
  },

  initialize(options) {
    const optionsToUse = options || {};
    this._gisView = optionsToUse.gisView || window.GISVIEW;
    this._router = optionsToUse.router || window.ROUTER;
    this._eventsBus = optionsToUse.eventsBus || this._router.eventsBus;
    this._config = optionsToUse.config || window.CONFIG;

    this._spot = optionsToUse.spot;
    this._lon = optionsToUse.lon;
    this._lat = optionsToUse.lat;
    this._buoyCode = optionsToUse.buoyCode;

    this._wfsService = new WFSService({
      getFeatureUrl: this._config.oceanogramme.getFeatureUrl,
      key: this._config.defaultKey,
      spotsLayer: this._config.oceanogramme.spotsLayer
    });

    this._fonctOnMessageReceive = _.bind(this._onMessageReceive, this);

    this._updateLangHandler = this._onUpdateLang.bind(this);
    $('body').on('lang:update', this._updateLangHandler);

    this._skipLinksView = new SkipLinksView({
      currentPage: 'oceano',
      isMenuDisplayed: false
    });
    this._$skipLinksView = $('#skip-links-container');
    this._$skipLinksView.html(this._skipLinksView.render().$el);

    // dirty but needed to communicate with cross-origin iframe
    // only do it when iframe code is belong to you ...
    // more information on https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
    window.addEventListener('message', this._fonctOnMessageReceive, false);
  },

  render() {
    this.$el.html(template({
      isBuoy: (this._buoyCode !== undefined)
    }));

    if (this._buoyCode) {
      this._renderBuoyTitle();
    } else if (this._spot) {
      this._renderToponymeTitle();
    } else {
      this._renderLonLatTitle();
    }

    this._renderOceanogramme();

    return this;
  },

  _renderBuoyTitle() {
    this._$title = this.$('.oceanogramme-label');
    this._$title.html(this._buoyCode);
    this.setPageTitle(`${$.i18n.t('oceanogramme.buoyTitle')} - ${this._buoyCode}`);

    return this;
  },

  _renderToponymeTitle() {
    this._$title = this.$('.oceanogramme-label');
    let title = '';

    this._wfsService.getFeaturePropertiesByCst(this._spot)
      .then(properties => {
        title = (properties && properties.toponyme) ? properties.toponyme : '';
      })
      .always(() => {
        this._$title.html(title);
        this.setPageTitle(`${$.i18n.t('oceanogramme.presentation.title')} - ${title}`);
      });

    return this;
  },

  _renderLonLatTitle() {
    this._$title = this.$('.oceanogramme-label');

    const roundLon = Math.round(this._lon * 10000) / 10000;
    const roundLat = Math.round(this._lat * 10000) / 10000;

    const title = `WGS84 : ${roundLat}° ; ${roundLon}°`;
    this._$title.html(title);
    this.setPageTitle(`${$.i18n.t('oceanogramme.presentation.title')} - ${title}`);
  },

  _renderOceanogramme() {
    this._$oceanogrammeContainer = this.$('.oceanogramme-container');

    OceanogrammeService().then(service => {
      this._startLoading();

      const lang = i18n.lng();

      const options = {
        duration: defaultDuration,
        delta: this._buoyCode ? defaultDeltaObservation : defaultDeltaForecast,
        lang: lang || 'fr'
      };
      if (this._buoyCode) {
        this._oceanogrammeUrl = service.getBuoyOceanogrammeUrl(this._buoyCode, { ...options, ...{ forecastComp: false } });
      } else if (this._spot) {
        this._oceanogrammeUrl = service.getSpotOceanogrammeUrl(this._spot, options);
      } else {
        this._oceanogrammeUrl = service.getLonLatOceanogrammeUrl(this._lon, this._lat, options);
      }

      this._$iframe = this.$('.oceanogramme-iframe');
      this._$iframe.on('load', this._stopLoading.bind(this));

      this._$iframe.attr('src', this._oceanogrammeUrl.toString());

      this._renderMobileLink();
    });
  },

  _renderMobileLink() {
    this._$mobileButton = this.$('.oceanogramme-mobile-link');
    this._$mobileButton.attr('href', this._oceanogrammeUrl.toString());
  },

  _startLoading() {
    if (this._isLoading) {
      return;
    }
    this._isLoading = true;
    Loading.start(this._$oceanogrammeContainer, { overlay: false, isAbsolute: true });
  },

  _stopLoading() {
    if (!this._isLoading) {
      return;
    }
    this._isLoading = false;
    Loading.stop(this._$oceanogrammeContainer);
  },

  _onBackClick() {
    this._router.navigate('donnees', true);
  },

  _refresh() {
    if (this._isLoading) {
      return;
    }
    this._startLoading();
    this._$iframe.attr('src', this._oceanogrammeUrl.toString());
  },

  _onMessageReceive(event) {
    // ALWAYS test if the message is from the origin you expect
    const origin = event.origin || event.originalEvent.origin;
    if (origin !== this._config.oceanogramme.baseServiceUrl) {
      return;
    }
    const message = event.data;

    if (message === 'oceanogramme_loading') {
      this._startLoading();
    } else if (message === 'oceanogramme_loaded') {
      this._stopLoading();
    }
  },

  _onUpdateLang() {
    const lang = i18n.lng();
    if (lang) {
      this._oceanogrammeUrl = urlHelper.setQueryParameters(this._oceanogrammeUrl, { lang });
    }
    this._stopLoading();
    this._refresh();
  },

  onClose() {
    window.removeEventListener('message', this._fonctOnMessageReceive);
    // If lang update event is declared
    if (this._updateLangHandler) {
      $('body').off('lang:update', this._updateLangHandler);
    }
    this.setPageTitle($.i18n.t('header.globalTitle'));
  }
});
