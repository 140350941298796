var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"originator") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" target=\"_blank\" title=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":1,"column":54},"end":{"line":1,"column":65}}}) : helper)))
    + "\" aria-label=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":90}}}) : helper)))
    + "\">\n	<img class=\"originator-logo\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"originator") : depth0)) != null ? lookupProperty(stack1,"logo") : stack1), depth0))
    + "\" alt=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"imgAlt") || (depth0 != null ? lookupProperty(depth0,"imgAlt") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"imgAlt","hash":{},"data":data,"loc":{"start":{"line":2,"column":63},"end":{"line":2,"column":75}}}) : helper)))
    + "\"/>\n</a>";
},"useData":true});