import SimpleBar from 'simplebar';

const $ = require('jquery');
const ShomView = require('../../core/shom-view');
const OceanoLayerUtils = require('../../utils/oceano/oceano-layer.js');
const CategoryUtil = require('../../utils/category');
const templateOceaLegendItem = require('../../../template/oceano/ocea-legend-item.hbs');
const ShomProxy = require('../../utils/proxy');

const LEFT_MOUSE_BUTTON = 1;

module.exports = ShomView.build({

  className: 'main-legend-panel hitable',

  imgRegex: /\.(gif|jpeg|jpg|png)$/i,

  events: {
    'click .main-legend-panel-top-close-button': '_onClose'
  },

  initialize(options = {}) {
    this._router = options.router || window.ROUTER;
    this._config = options.config || window.CONFIG;
    this._backRoute = options.backRoute || 'donnees';
    this._uuid = this._generateUuid(this.model);
    this.listenTo(this.model, 'change:includedInMap', this._onIncludedInMapChange.bind(this));
  },

  render() {
    this.removeLegend();
    this.setAndRenderLegend(this.model);
    this._addScrollbar();
    return this;
  },

  removeLegend() {
    this._removeDraggableEvent();
    this.$el.empty();
  },

  setAndRenderLegend(model) {
    this.$el.attr('id', this.getLegendItemViewElementId());
    if (model) {
      this._buildTemplate(model);
      this._setDraggable(this._uuid);
    } else {
      this.removeLegend();
    }
  },

  getLegendItemViewElementId() {
    return `main-legend-panel-${this._uuid}`;
  },

  _buildTemplate(model) {
    this.$el.html(templateOceaLegendItem({
      layer: model.toJSON(),
      legendUrl: this._getLegendUrlWithLang(model),
      title: this._getLayerTitle(model),
      legendIsImage: this._isLegendImage(model),
      legendLabel: $.i18n.t('legend.layer.legendaccess')
    }));
  },

  _setDraggable(uuid) {
    this._$draggable = this.$('.main-legend-panel-top');
    this._$boundingBox = $(document.body);
    this._mouseDownEventName = `mousedown.${uuid}`;
    this._mouseMoveEventName = `mousemove.${uuid}`;
    this._mouseUpEventName = `mouseup.${uuid}`;
    this._addDraggableEvent(this._$draggable);
  },

  _onIncludedInMapChange() {
    if (this.model && this.model.get('includedInMap')) {
      this.render();
    } else {
      this._onClose();
    }
  },

  _onClose() {
    this.stopListening(this.model);
    this.removeLegend();
    this._router.navigate(this._backRoute, true);
    this.remove();
  },

  _addScrollbar() {
    // eslint-disable-next-line no-new
    new SimpleBar(this.$('.main-legend-panel-list')[0], {
      autoHide: false,
      forceVisible: true,
      scrollbarMaxSize: 50,
      ariaLabel: $.i18n.t('menu.scrollableContent')
    });
  },

  _removeDraggableEvent() {
    this._$draggable && this._$draggable.off(this._mouseDownEventName);
    this._$boundingBox && this._$boundingBox.off(this._mouseMoveEventName).off(this._mouseUpEventName);
  },

  _addDraggableEvent() {
    this._$draggable.on(this._mouseDownEventName, e => {
      const $target = $(e.target);
      const mouseDownOnCloseButton = $target.hasClass('main-legend-panel-top-close-button');
      if (e.which !== LEFT_MOUSE_BUTTON || mouseDownOnCloseButton) {
        return;
      }
      const dr = this.$el.addClass('drag').css('cursor', 'move');
      const height = dr.outerHeight();
      const width = dr.outerWidth();
      const ypos = dr.offset().top + height - e.pageY;
      const xpos = dr.offset().left + width - e.pageX;
      this._$boundingBox.on(this._mouseMoveEventName, ebb => {
        const itop = ebb.pageY + ypos - height;
        const ileft = ebb.pageX + xpos - width;
        if (dr.hasClass('drag')) {
          dr.offset({ top: itop, left: ileft });
        }
      }).on(this._mouseUpEventName, () => {
        dr.removeClass('drag').css('cursor', 'auto');
        this._$boundingBox.off(this._mouseMoveEventName).off(this._mouseUpEventName);
      });
      e.preventDefault(); // disable selection of other elements
    });
  },

  _isLegendImage(model) {
    const legendUrl = model.get('legendUrl');
    return legendUrl && (legendUrl.match(this.imgRegex) || legendUrl.includes('format=image'));
  },

  _getLayerTitle(model) {
    const lang = window.portalLang;
    let layerTitle;
    if (model.get('layerType') === 'NCWMS') {
      layerTitle = OceanoLayerUtils.getLayerTitle(model, lang.toUpperCase());
    } else {
      layerTitle = lang !== 'fr' && model.get('englishName') || model.get('title');
    }
    return layerTitle;
  },

  _generateUuid(model) {
    return CategoryUtil.computeKey(model.get('identifier')).replace('/', '_').replace(':', '_');
  },

  _getLegendUrlWithLang(model) {
    const lang = window.portalLang;
    let legendUrl = this.model.get('legendUrl');
    const baseUrl = this._config.legend.baseUrl
      .replace('{{lang}}', lang.toUpperCase());
    legendUrl = legendUrl.replace(this._config.legend.folder, baseUrl);
    // We have to proxify external layers URL to deal with CORS
    if (model.get('external') === true) {
      legendUrl = ShomProxy.getProxifiedUrl(legendUrl);
    }
    return legendUrl;
  },

  postTranslate() {
    this.setAndRenderLegend(this.model);
  }
});
