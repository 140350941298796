import {
  LAYERTYPE_REFMAR, LAYERTYPE_FORECAST, LAYERTYPE_COPERNICUS, LAYERTYPE_KML_LAYER
} from '../utils/constants';

import imgSpotPoint from '../../img/spot-rond.svg';
import imgSpotTriangle from '../../img/spot-triangle.svg';

const _ = require('underscore');
const ShomView = require('../core/shom-view');
const template = require('../../template/displayed-layer.hbs');
const templateOceanogrammeLegend = require('../../template/oceanogramme/oceanogramme-legend.hbs');
const OpacitySliderView = require('./layer-opacity-slider.view');
const VisibilityView = require('./layer-visibility.view');

const LegendView = require('./legend/legend.view');
const LayerUtil = require('../utils/layer');

const DisplayedLayerView = ShomView.build({
  tagName: 'li',
  className: 'list-unstyled-item ui-state-default',

  events: {
    'click .more-info-button': '_onMoreInfoButtonClick',
    'click .remove-button': '_onRemoveLayer',
    'click .zoom-to-max-extend': '_onZoomToMaxExtentClick'
  },

  initialize(options = {}) {
    this._parentView = options.parentView;
    this._gisView = options.gisView || window.GISVIEW;
    this._router = options.router || window.ROUTER;
    this._context = this._router.getContext();
    this._stateNotifier = options.stateNotifier || window.STATE_NOTIFIER;
    this._gfiModeManager = options.gfiModeManager || window.POI_MODE_MANAGER;
    this._first = options.first === true;
    this._last = options.last === true;
    this._layerType = 'standard';

    this.index = options.index;
    this._gfiModeManager = options.poiModeManager || window.POI_MODE_MANAGER;
    this._eventsBus = options.eventsBus || this._router.eventsBus;
    _.bindAll(this, '_onNotification', 'render');
    this.listenTo(this._stateNotifier, 'notification', this._onNotification);

    this.listenTo(this._eventsBus, 'discuss:editor:update', this._onEditorUpdate.bind(this));

    // 3 states : default / featureInfo / download
    this._currentState = 'default';

    this._user = options.user || window.ROUTER.user;

    this.moreInfoanimationShowSpeed = 100;
  },

  render() {
    const isMoreInfo = this.model.toJSON().metadataURL
        || this.model.get('layerType') === LAYERTYPE_FORECAST
        || this.model.get('legendUrl');
    const isLoggedIn = this._user.get('isLoggedIn');
    this.$el.html(template({
      layer: this.model.toJSON(),
      isDownloadEnabled: !this._gisView.isGlobeEnabled(),
      layerLocalTitle: this._getLayerLocalTitle(),
      customTitle: this._customTitle,
      itemIndex: this.index,
      isActionAvailable: this.model.get('downloadable') || this.model.get('gfiResourceWmsV'),
      isMaxExtentDisabled: this.model.get('layerType') === LAYERTYPE_COPERNICUS || this.model.get('identifier') === LAYERTYPE_KML_LAYER,
      isMoreInfo,
      isLoggedIn
    }));
    this.$el.attr('id', this.model.get('identifier'));

    const canEdit = this._context.get('isEditor');

    this._$opacitySliderContainer = this.$el.find('.opacity-slider-root');
    this._$visibilityContainer = this.$el.find('.visibility-root');
    this._$orderRoot = this.$el.find('.order-root');

    this._$catalogToolParameterBtn = this.$el.find('.catalog-tools-parameters');
    this._$catalogToolActionsBtn = this.$el.find('.catalog-tools-actions');
    this._$downloadButton = this.$el.find('.disp-layer-tools-download-button');

    this._$layerTitle = this.$el.find('.displayed-layer-title-container');

    this._$moreInfoContainer = this.$el.find('.more-info-container');
    this._$moreInfoContainer.hide();

    this._renderOpacitySliderView();
    this._renderVisibilityView();
    this._renderMoreInfoView();

    if (!canEdit) {
      this._disableLayerButtons();
    }

    if (this.model.get('layerType') === LAYERTYPE_FORECAST) {
      this._gfiModeManager && this._gfiModeManager.stop();
    }

    return this;
  },

  _getLayerLocalTitle() {
    return window.portalLang === 'en' && this.model.get('englishName') ? this.model.get('englishName') : this.model.get('title');
  },

  _renderOpacitySliderView() {
    this._opacitySliderView && this._opacitySliderView.remove();
    this._opacitySliderView = new OpacitySliderView({
      model: this.model
    });
    this._$opacitySliderContainer.html(this._opacitySliderView.render().$el);
  },

  _renderVisibilityView() {
    this._visibilityView && this._visibilityView.remove();
    this._visibilityView = new VisibilityView({
      model: this.model
    });
    this._$visibilityContainer.html(this._visibilityView.render().$el);
  },

  _onRemoveLayer() {
    const layerType = this.model.get('layerType');
    if (layerType === LAYERTYPE_REFMAR) {
      this._gisView.removeSensorLayer(this.model.get('olLayer'), false);
      this._router.subtractRefmarLayer();
    }

    if (layerType === LAYERTYPE_FORECAST) {
      this._gisView.removeOceanogrammeGis();
      this._router.subtractForecastLayer();
    }

    if (layerType === LAYERTYPE_COPERNICUS) {
      this._gisView.removeCopernicusGis(this.model.get('name'));
    }

    this.model.set('includedInMap', false);
    this._gfiModeManager.startGfiRequestableLayer(false);
  },

  _onMoreInfoButtonClick() {
    this._$moreInfoContainer.toggle(this.moreInfoanimationShowSpeed);
  },

  _onZoomToMaxExtentClick() {
    this._gisView.zoomToLayerMaxExtent(this.model);
  },

  _renderMoreInfoView() {
    if (this.model.get('layerType') === LAYERTYPE_FORECAST) {
      this._$moreInfoContainer.html(templateOceanogrammeLegend({
        imgSpotPoint,
        imgSpotTriangle
      }));
    } else {
      this._moreInfoView && this._moreInfoView.remove();
      this._moreInfoView = new LegendView({
        model: this.model
      });
      this._$moreInfoContainer.html(this._moreInfoView.render().$el);
    }
  },

  /** ***************************** */
  /** ****** STATE FUNCTION ******* */
  /** ***************************** */

  changeState(newState) {
    if (this._currentState === newState) {
      return;
    }

    this.stopCurrentState();

    if (newState === 'default') {
      this._parentView.changeState('featureInfo');
    } else if (newState === 'download') {
      this._parentView.changeState('default');
      this._$downloadButton.addClass('active');
    } else {
      return;
    }
    this._currentState = newState;
  },

  stopCurrentState() {
    if (this._currentState === 'default') {
      return;
    }

    if (this._currentState === 'download') {
      this._$downloadButton.removeClass('active');
    }

    this._currentState = 'default';
  },

  resetToolsState() {
    this.changeState('default');
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onNotification() {
  },

  getLayerType() {
    return this._layerType;
  },

  setValuesAndRender(/* values */) {
    // nothing to do for now
    // awaiting further development.
  },

  onClose() {
    this._visibilityView && this._visibilityView.remove();
    this._opacitySliderView && this._opacitySliderView.remove();
    this._addRemoveView && this._addRemoveView.remove();
  },

  postTranslate(lang) {
    if (this._customTitle) {
      return;
    }
    const layerNameWithCategory = LayerUtil.buildLayerNameWithCategory(this.model, lang);

    this._$layerTitle.text(layerNameWithCategory);
  },

  _disableLayerButtons() {
    this._$opacitySliderContainer.addClass('layer-button-disabled');
    this._$visibilityContainer.addClass('layer-button-disabled');
    this.$('.more-info-button').addClass('hide');
  },

  _onEditorUpdate(isEditorUpdate) {
    if (isEditorUpdate) {
      this.render();
    }
  }
});

module.exports = DisplayedLayerView;
