var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"customTitle") : depth0), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"layer") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"customTitle") : depth0), depth0))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"layer") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"11":function(container,depth0,helpers,partials,data) {
    return "catalog.button.maxextent.tooltipDisabled";
},"13":function(container,depth0,helpers,partials,data) {
    return "catalog.button.maxextent.tooltip";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <i class=\"fas fa-download download-button "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isDownloadEnabled") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":54},"end":{"line":29,"column":102}}})) != null ? stack1 : "")
    + " tab-material-icons\"\n               data-i18n=\"[title]catalog.button.download.tooltip;[aria-label]catalog.button.download.tooltip\"></i>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"18":function(container,depth0,helpers,partials,data) {
    return "            <a href=\"#\" role=\"button\" class=\"more-info-button\" data-i18n=\"[title]catalog.button.info.tooltip;[aria-label]catalog.button.info.tooltip\"><i class=\"fas fa-angle-double-down\"></i></a>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"ocea-container ocean-copernicus-layer\">\n                <a href=\"#\" role=\"button\" class=\"ocean-copernicus-layer-btn "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCopernicusLayerDisplayed") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":76},"end":{"line":44,"column":123}}})) != null ? stack1 : "")
    + "\"\n                     data-i18n=\"[title]oceano.nav.button.dispCopernicusLayer.help\"></a>\n                <div class=\"ocean-copernicus-layer-txt\" data-i18n=\"oceano.nav.button.dispCopernicusLayer.label\"></div>\n            </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"displayed-layer-item\">\n\n    <div class=\"remove-button  disp-layer-presentation-buttons\" role=\"presentation\" >\n        <a href=\"#\" role=\"button\" data-i18n=\"[title]catalog.button.addremove.tooltip;[aria-label]catalog.button.addremove.tooltip\">\n            <i class=\"material-icons tab-material-icons\">clear</i>\n        </a>\n    </div>\n\n    <div class=\"layer-item-first-line\">\n        <span class=\"layer-title-container displayed-layer-title-container\" title=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"customTitle") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":10,"column":83},"end":{"line":10,"column":161}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"customTitle") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </div>\n\n    <div class=\"layer-item-second-line\">\n        <span class=\"disp-layer-presentation-buttons\" role=\"presentation\">\n            <span class=\"visibility-root tab-material-icons\"></span>\n            <a class=\"zoom-to-max-extend"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMaxExtentDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":40},"end":{"line":22,"column":83}}})) != null ? stack1 : "")
    + "\" role=\"button\" href=\"#\"\n               data-i18n=\"[title]"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMaxExtentDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":23,"column":33},"end":{"line":23,"column":147}}})) != null ? stack1 : "")
    + ";[aria-label]"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMaxExtentDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":23,"column":160},"end":{"line":23,"column":274}}})) != null ? stack1 : "")
    + "\">\n                <i class=\"fas fa-expand\"></i>\n            </a>\n        </span>\n        <div class=\"opacity-slider-root\" data-i18n=\"[title]catalog.slider.opacity.tooltip\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"layer") : depth0)) != null ? lookupProperty(stack1,"downloadable") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"layer") : depth0)) != null ? lookupProperty(stack1,"metadataURL") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":34,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n	<div class=\"ocea-more-tools-container\">\n		<div class=\"ocea-glob-date-container row\" id=\"ocea-glob-date-container-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"itemIndex") : depth0), depth0))
    + "\"></div>\n\n        <div class=\"ocea-container\" id=\"ocea-palette-container-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"itemIndex") : depth0), depth0))
    + "\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"layer") : depth0)) != null ? lookupProperty(stack1,"copernicusLink") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":48,"column":15}}})) != null ? stack1 : "")
    + "\n        <h5 class=\"tools-title\" data-i18n=\"oceano.nav.settings\"></h5>\n        <div class=\"ocea-container\" id=\"ocea-depth-container-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"itemIndex") : depth0), depth0))
    + "\"></div>\n        <div class=\"ocea-container\" id=\"ocea-unit-container-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"itemIndex") : depth0), depth0))
    + "\"></div>\n\n        <h5 class=\"tools-title\" data-i18n=\"oceano.nav.tools\"></h5>\n        <div class=\"ocea-container\" id=\"ocea-tools-container-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"itemIndex") : depth0), depth0))
    + "\"></div>\n	</div>\n\n    <div class=\"more-info-container\"></div>\n</div>\n";
},"useData":true});