var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel-heading\">\n  <h4 class=\"panel-title\">\n    <a role=\"listitem\" class=\"accordion-toggle collapsed link-collapse-category\" data-toggle=\"collapse\" data-parent=\"#categories-accordion\" href=\"#collapse-category-"
    + alias4(((helper = (helper = lookupProperty(helpers,"category") || (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data,"loc":{"start":{"line":3,"column":165},"end":{"line":3,"column":177}}}) : helper)))
    + "\">\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"categoryTranslate") || (depth0 != null ? lookupProperty(depth0,"categoryTranslate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"categoryTranslate","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":29}}}) : helper)))
    + "\n    </a>\n  </h4>\n</div>\n<div id=\"collapse-category-"
    + alias4(((helper = (helper = lookupProperty(helpers,"category") || (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data,"loc":{"start":{"line":8,"column":27},"end":{"line":8,"column":39}}}) : helper)))
    + "\" class=\"panel-collapse collapse\">\n  <div role=\"list\" class=\"panel-body category-layers-root\">\n  </div>\n</div>\n";
},"useData":true});