import SimpleBar from 'simplebar';

import { generateRandomString } from '@shom2/carto/utils/random';

const $ = require('jquery');
const _ = require('underscore');

const ShomView = require('../../../core/shom-view.js');
const LegendItem = require('../../../model/drawing/legend/legend-item');
const LegendItemCollection = require('../../../collection/drawing/legend/legend-items');
const LegendItemView = require('./legend-item.view');

const template = require('../../../../template/drawing/legend/legend.hbs');

const initialWidth = 290;
const initialHeight = 160;

const keyValueSeparator = ' : ';
const endline = '\n';
const endLineUser = '[[#n]]';

const LegendView = ShomView.build({
  className: 'legend-panel-container',

  initialize(options) {
    const optionsToUse = options || {};
    this.collection = new LegendItemCollection();
    this._config = window.CONFIG || optionsToUse.config;
    this.parseLegend(optionsToUse.rawLegend, optionsToUse.featureSource);
    this._gisView = optionsToUse.gisView || window.GISVIEW;
    this._session = optionsToUse.session;
    this.listenTo(this.collection, 'add remove reset', this.updateEmptyCollectionTextVisibility);
    this.listenTo(this._session, 'discuss:session:update', this._onSessionUpdate);
    this.listenTo(this._session, 'discuss:session:close', this._onSessionClosed);
  },

  render() {
    const collLength = this.collection.length;
    this.$el.html(template({ legendItemsCount: collLength }));
    if (collLength > 0) {
      this.$('.content .legend-list').empty();
      const isEditor = this._session.isCurrentUserEditor();
      this.collection.forEach(_.bind(function (item) {
        this.renderLegendItem(item, isEditor);
      }, this), this);
    }

    this.$('.legend-panel').draggable({
      containment: '.map-container',
      handle: 'h2'
    }).resizable({
      minHeight: initialHeight,
      minWidth: initialWidth
    });
    this._addScrollbar();
    return this;
  },

  updateEmptyCollectionTextVisibility() {
    if (this.collection.length === 0) {
      this.$('.no-legend-item').show();
    } else {
      this.$('.no-legend-item').hide();
    }
  },

  initDraggable() {
    const drawInfo = this.getInitialPositionAndSize();
    this.$('.legend-panel').css({
      left: `${drawInfo.x}px`,
      top: `${drawInfo.y}px`
    });
    this.$('.legend-panel').width(drawInfo.w).height(drawInfo.h);
  },

  renderLegendItem(legendItem, isEditor) {
    const legendItemView = new LegendItemView({
      model: legendItem,
      isEditor
    }).render();
    this.$('.content .legend-list').append(legendItemView.$el);
  },

  getRawLegend() {
    let output = '';
    const coord = this._getLegendCoordinates();
    const size = this._getLegendSize();
    // La première ligne est une ligne d'entete : LONG, LAT,LARGEUR,HAUTEUR
    output += `${coord.long}, ${coord.lat},${size.w},${size.h}${endline}`;
    this.collection.forEach(legendItem => {
      output += legendItem.get('featureId') + keyValueSeparator + legendItem.get('label').replace(/\n/g, endLineUser) + endline;
    });
    return output;
  },

  _getLegendCoordinates() {
    const offset = this.$('.legend-panel').offset();
    const parentOffset = $('.legend-panel-container').offset();
    const pixel = [offset.left + parentOffset.left, offset.top + parentOffset.top];
    const position = this._gisView.getCoordinateFromPixel(pixel);
    return {
      long: position[1],
      lat: position[0]
    };
  },

  _getLegendSize() {
    const $legend = this.$('.legend-panel');
    return {
      w: $legend.width(),
      h: $legend.height()
    };
  },

  parseLegend(rawLegends, featureSource) {
    this.collection.reset();
    const tempArray = [];
    if (!rawLegends) {
      this.collection.add([], { silent: true });
      return;
    }

    const lines = rawLegends.split(endline);
    this.parseFirstLine(lines[0]);
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i];
      if (line) {
        const pos = line.indexOf(keyValueSeparator);
        const featureId = line.substr(0, pos);
        // ignore existing legend items which contains 'undefined' ids
        if (featureId && featureId !== 'undefined') {
          const feature = featureSource.getFeatureById(featureId);
          const label = line.substr(pos + keyValueSeparator.length).replace(/\[\[#n]]/g, endline);
          tempArray.push(new LegendItem({
            featureId,
            feature,
            label
          }));
        }
      }
    }

    this.collection.add(tempArray, { silent: true });
  },

  parseFirstLine(line) {
    const arr = line.split(',');
    this.legendWidth = arr[2];
    this.legendHeight = arr[3];
  },

  getInitialPositionAndSize() {
    let x = 0; let
      y = 0;
    const parentWidth = this.$el.width();
    const parentHeight = this.$el.height();
    if (parentWidth && parentHeight) {
      const offset = this.$el.offset();
      x = parentWidth - offset.left;
      y = parentHeight - offset.top;
    }
    return {
      x: x - initialWidth - 20,
      y: y - initialHeight - 302,
      w: this.legendWidth ? Math.max(this.legendWidth, initialWidth) : initialWidth,
      h: this.legendHeight ? Math.max(this.legendHeight, initialHeight) : initialHeight
    };
  },

  addNewLegendItem(feature) {
    let featureId = feature.getId();
    if (!featureId) {
      featureId = generateRandomString(16);
      feature.setId(featureId);
    }

    if (!this.collection.get(featureId)) {
      const legendItem = new LegendItem({
        featureId,
        feature,
        label: $.i18n.t('drawing.legend.new-legend-item-label')
      });
      this.collection.add(legendItem);
      const isEditor = this._session.isCurrentUserEditor();
      this.renderLegendItem(legendItem, isEditor);
    }
  },

  remove() {
    this.stopListening(this.collection, 'add remove reset');
    this.stopListening(this._session);
    LegendView.__super__.remove.apply(this, arguments);
  },

  _onSessionUpdate(event) {
    if (event.updateEditor) {
      let $legend = this.$('.legend-panel');
      const width = $legend.width();
      const height = $legend.height();
      const left = parseInt($legend.css('left'), 10) || 0;
      const top = parseInt($legend.css('top'), 10) || 0;
      this.render();
      $legend = this.$('.legend-panel');
      $legend.css({
        left: `${left}px`,
        top: `${top}px`
      });
      $legend.width(width).height(height);
    }
  },

  _onSessionClosed() {

  },

  updateWith(legends) {
    const tempArray = legends.map(legend => new LegendItem({
      featureId: legend.id,
      feature: legend.feature,
      label: legend.label
    }));
    this.collection.add(tempArray, { silent: true });
    this.updateEmptyCollectionTextVisibility();
    const isEditor = this._session.isCurrentUserEditor();
    this.$('.content .legend-list').empty();
    this.collection.forEach(_.bind(function (item) {
      this.renderLegendItem(item, isEditor);
    }, this), this);
    this.resize();
  },

  resize() {
    const $legend = this.$('.legend-panel');
    const $parent = $('.legend-panel').parent();
    const width = $legend.width();
    const height = $legend.height();
    $legend.width(width).height(height);
    $legend.css('left', `${$parent.width() - width - 82}px`);
    $legend.css('top', `${$parent.height() - height - 332}px`);
  },

  _addScrollbar() {
    // eslint-disable-next-line no-new
    new SimpleBar(this.$('.content')[0], {
      autoHide: false,
      forceVisible: true,
      scrollbarMaxSize: 50,
      ariaLabel: $.i18n.t('menu.scrollableContent')
    });
  }
});

module.exports = LegendView;
