import SimpleBar from 'simplebar';

const $ = require('jquery');
const ShomView = require('../core/shom-view');
const template = require('../../template/layers-on-maps.hbs');
const LayersView = require('./displayed-layers.view');

module.exports = ShomView.build({
  events: {
    'click .change-lng-fr': 'setFrenchLang',
    'click #open-arrow': '_openOrCloseLayersContainer'
  },

  initialize() {
    this._containerIsOpen = false;
    this.moreInfoanimationShowSpeed = 100;
  },

  render() {
    this.$el.html(
      template({})
    );
    this._draggableContainer = $('#layers-on-maps');
    this._openArrowIcon = this.$('#open-arrow i');

    this._draggableContainer.draggable({
      handle: '.my-layers-title',
      start: () => this._draggableContainer.removeClass('floatingRight'),
      containment: '.map-container'
    });

    this._displayedLayersView = new LayersView({
      includedInMap: true
    });

    this._$displayedLayersList = this.$('#displayed-layers-list');
    this._$displayedLayersList.html(this._displayedLayersView.render().$el);

    this._draggableContainer.addClass('floatingRight');
    this._openOrCloseLayersContainer();

    this._addScrollbar();

    return this;
  },

  _openOrCloseLayersContainer() {
    if (this._containerIsOpen) {
      this._$displayedLayersList.hide(this.moreInfoanimationShowSpeed);
      this._containerIsOpen = !this._containerIsOpen;
      this._openArrowIcon.addClass('fa-chevron-left');
      this._openArrowIcon.removeClass('fa-chevron-right');
    } else {
      this._$displayedLayersList.show(this.moreInfoanimationShowSpeed);
      this._containerIsOpen = !this._containerIsOpen;
      this._openArrowIcon.removeClass('fa-chevron-left');
      this._openArrowIcon.addClass('fa-chevron-right');
    }
  },

  _addScrollbar() {
    // eslint-disable-next-line no-new
    new SimpleBar(this.$('#displayed-layers-list')[0], {
      autoHide: false,
      forceVisible: true,
      scrollbarMaxSize: 50,
      ariaLabel: $.i18n.t('menu.scrollableContent')
    });
  }

});
