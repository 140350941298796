const _ = require('underscore');
const $ = require('jquery');
const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/ddm/ddm-detail/ddm-detail.hbs');
const SkipLinksView = require('../../skip-links.view');
const DDMDataView = require('./ddm-data.view');
const DDMInfoView = require('./ddm-info.view');
const DDMContactView = require('./ddm-contact.view');
const DDMTextualDataView = require('./ddm-data-textual.view');
const DDMChronoView = require('./ddm-chrono.view');
const DDMOrgaView = require('./ddm-organism.view');
const DDMFooterView = require('../custom-view/ddm-footer.view');
const DDMWorkingButton = require('../working-button');
const DDMDlModalView = require('../ddm-dl/ddm-dl-modal.view');
const SensorBottomView = require('../sensor-bottom.view');

const DDMSensorReader = require('../../../utils/ddm/ddm-sensor-reader');
const DDMUtils = require('../../../utils/ddm/ddm-utils');
const AuthZones = require('../../../utils/auth-zones');

module.exports = ShomView.build({
  events: {
    'click .btn-back': '_onBackClick',
    'click #ddm-detail .direct-dl-btn': '_onDirectDownloadButtonClick',
    'click #ddm-detail .streams-dl-btn': '_onStreamDownloadButtonClick',
    'click #tab-chrono': '_onClickTabChrono',
    'click #ddm-tab-textual': '_renderTextualDataView'
  },

  initialize(options) {
    this._router = options.router || window.ROUTER;
    this.config = options.config || window.CONFIG;
    this._modalView = options.modalView || window.MODALVIEW;
    this.collection = options.collection || window.LAYERS;
    this._additionnalInfos = options.additionnalInfos || window.ADDITIONALINFO;
    this._idSensor = options.idSensor;
    this._parentView = options.parentView;

    this._ddmUtils = new DDMUtils(this.config);

    this._initData();
  },

  _initData() {
    this._searchTideGaugeIfNeeded()
      .then(_.bind(this._fetchSensorDescription, this))
      .then(_.bind(this._saveSensorDescription, this))
      .then(_.bind(this._getLastValue, this))
      .then(_.bind(this._initViews, this))
      .then(_.bind(this._renderViews, this))
      .fail(_.bind(this._renderErrorView, this));
  },

  _renderErrorView() {
    $('#ddm-btn-flux').hide();
    $('#ddm-btn-download').hide();
    $('#ddm-tabs').hide();
    $('#timePeriod').hide();
    $('#ddm-footer').hide();
    $('#ddm-error').show();
  },

  _searchTideGaugeIfNeeded() {
    if (this._idSensor > 0) {
      return $.Deferred().resolve(+this._idSensor);
    }

    return this._ddmUtils.searchTideGaugeId(this._idSensor);
  },

  _getLastValue() {
    return this._ddmUtils.getLastValueAndDate(this._idSensor);
  },

  _fetchSensorDescription(tideGaugeId) {
    this._idSensor = tideGaugeId;
    return this._ddmUtils.getSensorDesc(this._idSensor);
  },

  _saveSensorDescription(sensorDescription) {
    this._sensorDescription = sensorDescription;
    this._parentView._loadRefmarLayersIfNeeded(this._sensorDescription);
  },

  /** ***************************** */
  /** **** INIT VIEWS FUNCTION **** */
  /** ***************************** */

  _initViews(last) {
    const lastValue = last.value !== null ? last.value.toFixed(3) : 'N/A';
    this._initInfoView(lastValue);

    this._initSkipLinksView();
    this._initContactView();
    this._initOrgaView();
    this._initFooterView();
    this._initDataView();
    this._initTextualDataView();
  },

  _initInfoView(latest_value) {
    this._infoView = new DDMInfoView({
      parentView: this,
      latest_value
    });
    this._$info = this.$el.find('#info');
    this._$info.html(this._infoView.render().$el);
  },

  _initSkipLinksView() {
    this._skipLinksView = new SkipLinksView({
      currentPage: 'ddm_detail',
      isMenuDisplayed: false
    });
    this._$skipLinksView = $('#skip-links-container');
    this._$skipLinksView.html(this._skipLinksView.render().$el);
  },

  _initDataView() {
    this._ddmDataView = new DDMDataView({
      sensorDescription: this._sensorDescription
    });

    this._$data = this.$el.find('#data');
    this._$data.html(this._ddmDataView.render().$el);
  },

  _initContactView() {
    this._contactView = new DDMContactView({
      parentView: this
    });
    this._$contact = this.$el.find('#contact');
    this._$contact.html(this._contactView.render().$el);
  },

  _initChronoView() {
    this._chronoView = new DDMChronoView({
      parentView: this,
      sensorDescription: this._sensorDescription
    });
    this._$chronogramme = this.$('#chronogramme');
  },

  _initOrgaView() {
    this._orgaView = new DDMOrgaView({
      parentView: this
    });
    this._$orga = this.$el.find('#orga');
    this._$orga.html(this._orgaView.render().$el);
  },

  _initFooterView() {
    this._footerView = new DDMFooterView({});
    this._$footerView = this.$el.find('#ddm-footer');
    this._$footerView.html(this._footerView.render().$el);
  },

  _initTextualDataView() {
    this._$textualData = this.$('#textual-data');
    this._textualDataView = new DDMTextualDataView({
      parentView: this,
      tidegaugeName: this._sensorDescription.name,
      idSensor: this._idSensor
    });
    this._$textualData.html(this._textualDataView.render().$el);
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template());
    AuthZones.checkAuth(this.$el);
    this.$title = this.$el.find('#title');
    this.$additionnalInfo = this.$el.find('#additionnalInfo');

    return this;
  },

  _renderViews() {
    this._renderTitle();
    this._renderPageTitle();
    this._renderAdditionnalInfos();
    this._renderInfoView();
    this._renderContactView();
    this._renderOrgaView();
    this._renderWorkingButton();
    this._renderDataView(true);
    this._renderTextualDataView();
  },

  _renderTitle() {
    this.$title.html(DDMSensorReader.getName(this._sensorDescription));
  },

  _renderPageTitle() {
    const name = DDMSensorReader.getName(this._sensorDescription);
    this.setPageTitle(`${$.i18n.t('ddm.station-title')} - ${name}`);
  },

  _renderAdditionnalInfos() {
    const isRONIM = this._sensorDescription.reseau === 'RONIM';
    const additionnalInfoKey = isRONIM ? 'REFMAR/RONIM' : 'REFMAR/OTHER';
    const additionnalInfo = this._additionnalInfos[additionnalInfoKey];
    if (additionnalInfo) {
      this.$additionnalInfo.html(additionnalInfo[window.portalLang]);
      this.$additionnalInfo.show();
    }
  },

  _renderInfoView() {
    this._infoView.setValuesAndRender(this._sensorDescription);
    return this;
  },

  _renderContactView() {
    this._contactView.setValuesAndRender(this._sensorDescription);
    return this;
  },

  _renderChronogrammeView() {
    this._$chronogramme.html(this._chronoView.render().$el);
    return this;
  },

  _renderOrgaView() {
    this._orgaView.setValuesAndRender(this._sensorDescription);
    return this;
  },

  _renderDataView(renderControls) {
    this._ddmDataView.updateViews(renderControls);
    return this;
  },

  _renderWorkingButton() {
    const $stationTitle = this.$el.find('#station-title-div');
    const workingState = DDMSensorReader.getState(this._sensorDescription);
    $stationTitle.prepend(
      new DDMWorkingButton({
        infoStation: {
          working: workingState
        }
      })
        .render()
        .$el.html()
    );

    return this;
  },

  _renderTextualDataView(event) {
    const isRenderOnStart = !event;
    this._textualDataView.setValuesAndRender(isRenderOnStart);
    return this;
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onDirectDownloadButtonClick() {
    this._router.navigate(
      `donnees/refmar/${this._idSensor}/download`,
      true
    );
  },

  _onBackClick() {
    if (!this._sensorDescription) {
      return this._router.navigate('donnees', true);
    }
    let layer = null;
    const network = DDMSensorReader.getNetwork(this._sensorDescription);
    const identifier = `REFMAR/${network}`;

    for (const i in this.collection.models) {
      if (
      // eslint-disable-next-line no-prototype-builtins
        this.collection.models.hasOwnProperty(i)
        && this.collection.models[i].get('identifier') === identifier
      ) {
        layer = this.collection.models[i];
        break;
      }
    }

    const sensorView = new SensorBottomView({
      model: layer,
      network
    });
    sensorView.loadFeatures().then(() => {
      if (layer !== null && !sensorView.model.get('includedInMap')) {
        sensorView.addLayerFeatures();
        sensorView.model.set('includedInMap', true);
      }
    });

    this._router.navigate('donnees', true);
  },

  _onStreamDownloadButtonClick() {
    const result = new DDMDlModalView({
      title: 'ddm.dl.stream.title',
      info: 'ddm.dl.stream.info',
      idSensor: this._idSensor,
      modalView: this._modalView
    });
    this._modalView.show(result);
  },

  /** ***************************** */
  /** ****** UTILS FUNCTION ******* */
  /** ***************************** */

  _loadCustomCss() {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = this.config.ddm.custom_html.style;
    document.getElementsByTagName('head')[0].appendChild(link);
  },

  _onClickTabChrono() {
    this._initChronoView();
    this._renderChronogrammeView();
  },

  onClose() {
    this._ddmDataView && this._ddmDataView.remove();
    this._chronoView && this._chronoView.remove();
    this._infoView && this._infoView.remove();
    this._contactView && this._contactView.remove();
    this._orgaView && this._orgaView.remove();
    this.setPageTitle($.i18n.t('header.globalTitle'));
  }
});
