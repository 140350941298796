var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel panel-default\">\n    <div class=\"panel-heading gfi-modal-title\">\n        <h4 class=\"panel-title\">\n            <a class=\"gfi-accordion-toggle"
    + alias4(((helper = (helper = lookupProperty(helpers,"toogleCollapse") || (depth0 != null ? lookupProperty(depth0,"toogleCollapse") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toogleCollapse","hash":{},"data":data,"loc":{"start":{"line":4,"column":42},"end":{"line":4,"column":60}}}) : helper)))
    + "\" data-toggle=\"collapse\" data-parent=\"#accordion\"\n               href=\"#collapse"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":40}}}) : helper)))
    + "\" id=\"a-toggle-accordion-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":5,"column":65},"end":{"line":5,"column":75}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":77},"end":{"line":5,"column":86}}}) : helper)))
    + "</a>\n        </h4>\n    </div>\n    <div id=\"collapse"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":8,"column":21},"end":{"line":8,"column":31}}}) : helper)))
    + "\" class=\"panel-collapse collapse"
    + alias4(((helper = (helper = lookupProperty(helpers,"collapse") || (depth0 != null ? lookupProperty(depth0,"collapse") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"collapse","hash":{},"data":data,"loc":{"start":{"line":8,"column":63},"end":{"line":8,"column":75}}}) : helper)))
    + "\">\n        <div class=\"panel-body\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"htmlTSContent") || (depth0 != null ? lookupProperty(depth0,"htmlTSContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"htmlTSContent","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":31}}}) : helper))) != null ? stack1 : "")
    + "\n            <div id=\"gfi-modal-tabs\" class=\"tabbable container-fluid\">\n                <ul class=\"nav nav-tabs modal-tabs\">\n                    <li class=\"active\">\n                        <a href=\"#tabTimeseries-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":14,"column":48},"end":{"line":14,"column":58}}}) : helper)))
    + "\" data-toggle=\"tab\" id=\"a-timeseries-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":14,"column":95},"end":{"line":14,"column":105}}}) : helper)))
    + "\"></a>\n                    </li>\n                    <li>\n                        <a href=\"#tabRoseCourant-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":17,"column":49},"end":{"line":17,"column":59}}}) : helper)))
    + "\" data-toggle=\"tab\" id=\"a-rose-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":17,"column":90},"end":{"line":17,"column":100}}}) : helper)))
    + "\"></a>\n                    </li>\n                </ul>\n                <div class=\"tab-content\">\n                    <div class=\"tab-pane active\" id=\"tabTimeseries-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":21,"column":67},"end":{"line":21,"column":77}}}) : helper)))
    + "\">\n                        <iframe class=\"iframe-gfi\" id=\"iframe-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":22,"column":62},"end":{"line":22,"column":72}}}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":22,"column":81},"end":{"line":22,"column":90}}}) : helper)))
    + "\" seamless frameborder=\"0\"></iframe>\n                    </div>\n                    <div class=\"tab-pane\" id=\"tabRoseCourant-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":24,"column":61},"end":{"line":24,"column":71}}}) : helper)))
    + "\">\n                        <div id=\"contentRoseCourant-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":25,"column":52},"end":{"line":25,"column":62}}}) : helper)))
    + "\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});