const ShomView = require('../core/shom-view.js');
const template = require('../../template/startup-modal.hbs');
const sanitizeHtml = require('sanitize-html');
const $ = require('jquery');

module.exports = ShomView.build({
  events: {
    'click .close': '_close'
  },

  initialize({ data } = { data: {} }) {
    this.data = data;
  },

  render() {
    const sanOptions = {
      allowedTags: ['b', 'i', 'em', 'strong', 'a', 'br'],
      allowedAttributes: {
        a: ['href', 'target']
      }
    };
    this.$el.html(
      template({
        title: this.data.title[window.portalLang],
        message: sanitizeHtml(
          this.data.message[window.portalLang],
          sanOptions
        ),
        partnersTitle: this.data.partners.title[window.portalLang],
        partnersLogos: this.data.partners.logos.map(logo => ({
          title: `${logo.title[window.portalLang]} - ${$.i18n.t('display.originator.title')}`,
          alt: `${$.i18n.t('display.originator.logo')} ${logo.title[window.portalLang]}`,
          image: logo.image,
          url: logo.url
        }))
      })
    );
    this.$el[0].showModal();
    return this;
  },

  _close() {
    const doNotDisplay = $('#startup-modal-dnd').is(':checked');
    window.localStorage.setItem('startup-modal-dnd', `${doNotDisplay}`);
    this.$el[0].close();
  }
});
